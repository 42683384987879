import BookIcon from '@mui/icons-material/Book';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import {
    BulkDeleteButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ShowButton,
    SimpleList,
    TextField,
    BooleanField,
    TextInput,
    usePermissions
} from 'react-admin';
import ClientVRFTitle from './ClientVRFTitle';
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";

export const PostIcon = BookIcon;

const ClientVRFFilter = props => (
    <Filter {...props}>
        <TextInput
            label="clientvrf.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const ClientVRFListBulkActions = props => (
    <Fragment>
        <BulkDeleteButton {...props}/>
    </Fragment>
);

const ClientVRFList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {permissions} = usePermissions();
    const styles = useStyles();
    return (
        <List
        {...props}
        title={<ClientVRFTitle />}
        bulkActionButtons={<ClientVRFListBulkActions />}
        filters={<ClientVRFFilter />}
        perPage={25}
        sort={{ field: 'id', order: 'ASC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => `${record.enabled}`}
                    tertiaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ):(
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" cellClassName={styles.name} />
                    <TextField source="comment" />
                    <TextField source="rm" />
                    <BooleanField source="enabled" cellClassName={styles.comment} />
                    <ListActionsToolbar>
                        {permissions === 'admin' ? <EditButton /> : <Fragment />}
                        <ShowButton />
                    </ListActionsToolbar>
                </Datagrid>
            )}
    </List>
    )
};

export default ClientVRFList;
