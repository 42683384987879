import React, { Fragment, useState } from 'react';
import {useListContext, useNotify, Button, Confirm, useDataProvider} from 'react-admin';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';

const RebootButton = () => {
    const notify = useNotify();
    const { selectedIds } = useListContext();
    const [open, setOpen] = useState(false);
    const dataProvider = useDataProvider();

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        selectedIds.forEach(id=>{
          dataProvider.rebootCpeAcs(id).then(response => {
            notify('Reboot launched', {type: 'success'});
          }).catch((e) => {
            notify('Error: Reboot not launched', {type: 'warning'})
          });
        })
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label="Reboot" onClick={handleClick}>
                <SettingsPowerIcon/>
            </Button>
            <Confirm
                isOpen={open}
                title="Reboot CPE"
                content="Are you sure you want to reboot these items?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}

export default RebootButton;