import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    NumberInput,
    BooleanInput,
    required,
} from 'react-admin';
import RadiusRealmTitle from './RadiusRealmTitle';

const RadiusRealmEdit = props => (
    <Edit title={<RadiusRealmTitle />} {...props}>
         <TabbedForm>
            <FormTab label="radiusrealm.form.summary">
                <TextInput disabled source="id" />
                <TextInput source="addr" validate={required()} />
                <NumberInput source="acct_port" validate={required()} />
                <NumberInput source="auth_port" validate={required()} />
                <TextInput source="secret" validate={required()} />
                <BooleanInput source="enabled" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default RadiusRealmEdit;
