import React from 'react';
import {
    BooleanInput,
    Edit,
    FormTab,
    AutocompleteInput,
    ReferenceInput,
    NumberInput,
    TabbedForm,
    TextInput,
    number,
    required,
} from 'react-admin';
import CpeTitle from './CpeTitle';

const CpeEdit = props => (
    <Edit title={<CpeTitle />} {...props}>
        <TabbedForm defaultValue={{ average_note: 0 }}>
            <FormTab label="cpe.form.summary">
                <TextInput disabled source="id" />
                <ReferenceInput label="Company" source="company" reference="companies" sort={{field: 'nom', order: 'ASC'}}>
                    <AutocompleteInput optionText="nom"/>
                </ReferenceInput>
                <TextInput disabled source="leoid" />
                <BooleanInput source="enabled" defaultValue />
            </FormTab>
            <FormTab label="cpe.form.network">
                <TextInput source="username" />
                <ReferenceInput label="Realm" source="realm" reference="realms" sort={{field: 'domain', order: 'ASC'}}>
                    <AutocompleteInput optionText="domain"/>
                </ReferenceInput>
                <TextInput source="password" />
                <TextInput source="ipv4_address" validate={required()} />
                <TextInput source="ipv6_address" validate={required()} />
                <TextInput source="ipv6_prefix" validate={required()} />
                <NumberInput source="bw_down" validate={[number()]} />
                <NumberInput source="bw_up" validate={[number()]}/>
            </FormTab>
            <FormTab label="cpe.form.miscellaneous">
                <TextInput multiline source="comment" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default CpeEdit;
