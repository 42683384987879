import CableIcon from '@mui/icons-material/Cable';
import CircuitCreate from './CircuitCreate';
import CircuitEdit from './CircuitEdit';
import CircuitList from './CircuitList';
import CircuitShow from './CircuitShow';

export default {
    list: CircuitList,
    create: CircuitCreate,
    edit: CircuitEdit,
    show: CircuitShow,
    icon: CableIcon,
};
