import React, {Children, cloneElement, Fragment, useEffect, useState} from 'react';
import { withStyles } from '@mui/styles';
import {
    useNotify,
    usePermissions,
    useRecordContext,
    useShowContext,
    fetchUtils,
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    TextField,
    Labeled,
    BooleanField,
    EditButton,
    Button,
    ArrayField,
    Datagrid,
    TopToolbar, useDataProvider
} from 'react-admin';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MemoryIcon from '@mui/icons-material/Memory';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import ConfigsTitle from './ConfigsTitle';
import * as Constants from "../../constants";
import hljs from "highlight.js";
import {Paper} from "@mui/material";

const ConfigField = () => {
    const record = useRecordContext();
    const [config, setConfig] = useState('');

    useEffect(() => {
        if(record) {
            setConfig(hljs.highlightAuto(record.generated_configuration).value);
        }
    }, [record])

    if (!record || config === '') return <React.Fragment/>;

    return <Labeled label={"Generated Configuration"}>
        <Paper sx={{padding: "10px"}}>
            <pre>
                <code class={"dust"} dangerouslySetInnerHTML={{__html: config}}></code>
            </pre>
        </Paper>
    </Labeled>
};

const GenerateConfButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    if (!record) return <React.Fragment/>;

    return  <Button label={"Generate"} onClick={e => {
                e.stopPropagation();
                dataProvider.generateConfig(record.id).then(response => {
                    notify('Generation launched', {type: 'success'});
                }).catch((e) => {
                    notify('Error: generation not launched', {type: 'warning'})
                });
            }}>
                <MemoryIcon/>
            </Button>
};


const PVRecetteConfButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    return  <Button label={"PV Recette"} onClick={e => {
                e.stopPropagation();
                dataProvider.getPvRecette(record.id).then(response => {
                   response.blob().then(blob => {
                       let url = window.URL.createObjectURL(blob);
                       let link = document.createElement('a');
                       link.href = url;
                       link.download = 'pvrecette_' + record.circuit_leoid + '.pdf';
                       link.click();
                   })

                }).catch((e) => {
                    console.log(e);
                });
            }}>
                <ReceiptIcon/>
            </Button>
};

const ConfigsShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
        <PVRecetteConfButton basepath={basePath} resource={resource} record={data}/>
        <GenerateConfButton basepath={basePath} resource={resource} record={data}/>
        <EditButton basepath={basePath} record={data} />
    </TopToolbar>
);

const DeletePrefixButton = props => {
    const notify = useNotify();
    return  <Button label={"Delete"} onClick={e => {
                e.stopPropagation();
                let apiUrl = Constants.APIURL;
                let options = {method: 'DELETE'};
                let resource = props.resource;
                let id = props.record.id;
                console.log(props);
                console.log(props.record);
                let url = `${apiUrl}/${resource}/prefixes/${id}/`;
                console.log(url);
                fetchUtils.fetchJson(url, options).then(response => {
                    const {json} = response;
                    console.log(json);
                    notify('Generation launched');
                }).catch((e) => {
                    notify('Error: generation not launched', 'warning')
                });
            }}>
                <RemoveCircleOutlineIcon/>
            </Button>
};

const AddPrefixButton = props => {
    return  <Button label={"Delete"} onClick={e => {
                e.stopPropagation();
                let apiUrl = Constants.APIURL;
                let resource = props.resource;
                let id = props.record.id;
                console.log(props);
                console.log(props.record);
                let url = `${apiUrl}/${resource}/prefixes/${id}/`;
                console.log(url);
            }}>
                <RemoveCircleOutlineIcon/>
            </Button>
};

const ConfigPrefixesAction = withStyles({
    toolbar: {
        alignItems: 'center',
        display: 'flex',
    },
})(({ classes, children, ...props }) => (
    <div className={classes.toolbar}>
        {Children.map(children, button => cloneElement(button, props))}
    </div>
));

const ConfigsShow = () => {
    const { permissions } = usePermissions();
    const { record } = useShowContext();

    return <Show title={<ConfigsTitle/>} actions={<ConfigsShowActions/>}>
            <TabbedShowLayout>
                <Tab label="configs.form.summary">
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <ReferenceField label="Circuit" source="circuit" reference="circuits" linkType={false}>
                        <TextField source="leoid"/>
                    </ReferenceField>
                    <TextField source="enabled"/>
                </Tab>
                <Tab label="configs.form.generation" path={"generation"}>
                    <TextField source="awx_jobs"/>
                    <ConfigField />
                </Tab>
                <Tab label="configs.form.variables" path={"variables"}>
                    <TextField source="type_cpe"/>
                    <TextField source="conf"/>
                    <TextField source="prefix_v4"/>
                    <TextField source={"id_operator"}/>
                    <BooleanField source="has_wifi"/>
                    <BooleanField source="has_intersite"/>
                    <BooleanField source="has_l2tp"/>
                    <ArrayField source="prefixes">
                        <Datagrid>
                            <TextField source="display" />
                            <TextField source="family" />
                            <TextField source="status" />
                            <ConfigPrefixesAction>
                                {permissions === 'Admin' ? <DeletePrefixButton /> : <Fragment />}
                             </ConfigPrefixesAction>
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="configs.form.data" path={"data"}>
                    <BooleanField source={"dhcp_data"}/>
                    <TextField source={"lan_data_client"}/>
                    <TextField source={"cpe_data_client"}/>
                </Tab>
                {record && record.has_wifi &&
                    <Tab label="configs.form.wifi" path={"wifi"}>
                        <TextField source={"wifi_ssid"}/>
                        <TextField source={"wifi_passphrase"}/>
                    </Tab>
                }
                {record && record.has_l2tp &&
                    <Tab label="configs.form.l2tp" path={"l2tp"}>
                        <TextField source={"ppp_local_address"}/>
                        <TextField source={"ppp_remote_address"}/>
                        <TextField source={"vpn_user"}/>
                    </Tab>
                }
                {record && record.has_intersite &&
                    <Tab label="configs.form.intersite" path={"intersite"}>
                        <TextField source={"vrf_intersite"}/>
                        <TextField source={"qos_intersite"}/>
                    </Tab>
                }
                <Tab label="configs.form.objects">
                    <DateField source="created" showTime/>
                    <ReferenceField label="Created By" source="created_by" reference="users">
                        <TextField source="email"/>
                    </ReferenceField>
                    <DateField source="modified" showTime/>
                    <ReferenceField label="Modified By" source="modified_by" reference="users">
                        <TextField source="email"/>
                    </ReferenceField>
                </Tab>
            </TabbedShowLayout>
        </Show>;
};

export default ConfigsShow;
