import React, { useState, useEffect } from "react";
import {
    fetchUtils,
    Edit,
    EditBase,
    SaveButton,
    TabbedForm,
    FormTab,
    BooleanInput,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    DeleteButton,
    Toolbar, useResourceContext, useRecordContext, useEditController
} from 'react-admin';
import ConfigsTitle from './ConfigsTitle';
import {RichTextInput} from 'ra-input-rich-text';
import * as Constants from "../../constants";
import { makeStyles } from '@mui/styles';

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
        <DeleteButton />
    </Toolbar>
);

const useStyles = makeStyles({
    richTextInput: {
        '& .ql-toolbar': {
            height: '0px'
        },
        '& .ql-editor': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            maxHeight: '300px',
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
            '&::after': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
            '& p': {
                marginBottom: '2px !important'
            }
        },
    },
});

const ConfigsEditBase = (props) => {
    const editContext = useEditController();
    const resource = useResourceContext();
    const record = useRecordContext();
    const [showWifiTab, setShowWifiTab] = useState(false);
    const [showIntersiteTab, setShowIntersiteTab] = useState(false);
    const [showL2tpTab, setShowL2tpTab] = useState(false);
    const [listeCpe, setListeCpe] = useState([]);
    const [listeConf, setListeConf] = useState([]);
    const styles = useStyles();

    useEffect(() => {
        let apiUrl = Constants.APIURL;
        let options = {method: 'OPTIONS'};
        let url = `${apiUrl}/${resource}/`;
        if(listeCpe.length===0){
            fetchUtils.fetchJson(url, options).then(response => {
                const {json} = response;
                let newrecord = json.actions.POST;
                setListeCpe(parseChoices(newrecord.type_cpe.choices));
                setListeConf(parseChoices(newrecord.conf.choices));
            });
        }
    });

    useEffect(()=>{
        setShowWifiTab(record.has_wifi);
        setShowIntersiteTab(record.has_intersite);
        setShowL2tpTab(record.has_l2tp);
    }, [record]);

    if (editContext.isLoading) return null;

    const parseChoices = (choices) => {
        let selectChoices = [];
        choices.forEach((res) => {
            selectChoices.push({id: res.value, name: res.display_name})
        });
        return selectChoices;
    }

    return (
        <EditBase {...props}>
            <TabbedForm toolbar={<CustomToolbar />}>
                <FormTab label="configs.form.summary">
                    <ReferenceInput label="Circuit" source="circuit" reference="circuits"
                                    sort={{field: 'leoid', order: 'ASC'}}>
                        <AutocompleteInput optionText="leoid"/>
                    </ReferenceInput>
                    <BooleanInput source="enabled"/>
                </FormTab>
                <FormTab label="configs.form.variables" path={"variables"}>
                    {listeCpe.length && <SelectInput source="type_cpe" choices={listeCpe}/>}
                    {listeConf.length && <SelectInput source="conf" choices={listeConf}/>}
                    <TextInput source={"id_operator"}/>
                    <BooleanInput source="has_wifi" onChange={() => setShowWifiTab(!showWifiTab)}/>
                    <BooleanInput source="has_intersite" onChange={() => setShowIntersiteTab(!showIntersiteTab)}/>
                    <BooleanInput source="has_l2tp" onChange={() => setShowL2tpTab(!showL2tpTab)}/>
                </FormTab>
                <FormTab label="configs.form.data" path={"data"}>
                    <BooleanInput source={"dhcp_data"}/>
                    <TextInput source={"lan_data_client"}/>
                    <TextInput source={"cpe_data_client"}/>
                </FormTab>
                {showWifiTab &&
                    <FormTab label="configs.form.wifi" path={"wifi"}>
                        <TextInput source={"wifi_ssid"}/>
                        <TextInput source={"wifi_passphrase"}/>
                    </FormTab>
                }
                {showL2tpTab &&
                    <FormTab label="configs.form.l2tp" path={"l2tp"}>
                        <TextInput source={"ppp_local_address"}/>
                        <TextInput source={"ppp_remote_address"}/>
                        <TextInput source={"vpn_user"}/>
                    </FormTab>
                }
                {showIntersiteTab &&
                    <FormTab label="configs.form.intersite" path={"intersite"}>
                        <ReferenceInput label="VRF Intersite" source="vrf_intersite" reference="clientvrfs"
                                        sort={{field: 'name', order: 'ASC'}}>
                            <AutocompleteInput optionText="name"/>
                        </ReferenceInput>
                        <NumberInput source={"qos_intersite"}/>
                    </FormTab>
                }
                <FormTab label="configs.form.conf" path={"conf"}>
                    <RichTextInput
                        className={styles.richTextInput}
                        source={"generated_configuration"}
                        toolbar={[]}
                        format={v => v ? v.replace(/\n/g, '<br/>'): ''}
                    />
                </FormTab>
            </TabbedForm>
        </EditBase>
    )
};

const ConfigsEdit = (props) => {
    return (
        <Edit title={<ConfigsTitle/>} mutationMode={"pessimistic"} {...props}>
            <ConfigsEditBase {...props} />
        </Edit>
    )
}

export default ConfigsEdit;