import BookIcon from '@mui/icons-material/Book';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import {
    BulkDeleteButton,
    Datagrid, EditButton,
    Filter,
    List,
    ShowButton,
    SimpleList,
    TextField,
    TextInput,
    UrlField, usePermissions
} from 'react-admin';
import DeviceTypeTitle from './DeviceTypeTitle';
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";

export const PostIcon = BookIcon;

const DeviceTypeFilter = props => (
    <Filter {...props}>
        <TextInput
            label="Rechercher"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const DeviceTypeListBulkActions = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const DeviceTypeList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {permissions} = usePermissions();
    const styles = useStyles();

    return (
        <List
        {...props}
        title={<DeviceTypeTitle />}
        bulkActionButtons={<DeviceTypeListBulkActions />}
        filters={<DeviceTypeFilter />}
        perPage={25}
        sort={{ field: 'id', order: 'ASC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.model}
                    secondaryText={record => `${record.comment}`}
                    tertiaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ):(
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="model" cellClassName={styles.name}  label="Modèle du CPE"/>
                    <UrlField source="url_netbox" target="_blank"/>
                    <ListActionsToolbar>
                        {permissions === 'admin' ? <EditButton /> : <Fragment />}
                        <ShowButton />
                    </ListActionsToolbar>
                </Datagrid>
            )}
    </List>
    )
};

export default DeviceTypeList;
