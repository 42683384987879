import CallSplitIcon from '@mui/icons-material/CallSplit';
import TypesCircuitCreate from './TypesCircuitCreate';
import TypesCircuitEdit from './TypesCircuitEdit';
import TypesCircuitList from './TypesCircuitList';
import TypesCircuitShow from './TypesCircuitShow';

export default {
    list: TypesCircuitList,
    create: TypesCircuitCreate,
    edit: TypesCircuitEdit,
    show: TypesCircuitShow,
    icon: CallSplitIcon,
};
