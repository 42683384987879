import BookIcon from '@mui/icons-material/Book';
import CollectL2TPCreate from './CollectL2TPCreate';
import CollectL2TPEdit from './CollectL2TPEdit';
import CollectL2TPList from './CollectL2TPList';
import CollectL2TPShow from './CollectL2TPShow';

export default {
    list: CollectL2TPList,
    create: CollectL2TPCreate,
    edit: CollectL2TPEdit,
    show: CollectL2TPShow,
    icon: BookIcon,
};
