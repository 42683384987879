import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import L2tpCreate from './L2tpCreate';
import L2tpEdit from './L2tpEdit';
import L2tpList from './L2tpList';
import L2tpShow from './L2tpShow';

export default {
    list: L2tpList,
    create: L2tpCreate,
    edit: L2tpEdit,
    show: L2tpShow,
    icon: SettingsEthernetIcon,
};
