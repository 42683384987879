import React from 'react';
import {
    DateField,
    Show,
    useRecordContext,
    useShowContext,
    ReferenceField,
    Tab,
    TabbedShowLayout,
    TextField,
    UrlField,
    ArrayField,
    Datagrid, useDataProvider,
    Labeled
} from 'react-admin';
import {
    TextField as TextFieldMaterial,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    styled,
    IconButton,
    CircularProgress,
    Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CircuitTitle from './CircuitTitle';
import Ansi from 'ansi-to-html';

const ansi = new Ansi({
  stream: true,
  colors: {
    0: '#000',
    1: '#A30000',
    2: '#486B00',
    3: '#795600',
    4: '#00A',
    5: '#A0A',
    6: '#004368',
    7: '#AAA',
    8: '#555',
    9: '#F55',
    10: '#5F5',
    11: '#FF5',
    12: '#55F',
    13: '#F5F',
    14: '#5FF',
    15: '#FFF',
  },
});

const pLogEvent = {
  margin: '3px'
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const AwxJobsComponent = () => {
    const { record } = useShowContext();
    const [openjoblogs, setOpenJobLogs] = React.useState(false);
    const [logs, setLogs] = React.useState([])
    const [loadinglogs, setLoadingLogs] = React.useState(true);
    const dataProvider = useDataProvider();

    const handleClickOpen = () => {
        setLoadingLogs(true);
        dataProvider.getCircuitAwxJobLogs(record.id).then(response => {
            setLogs(response.data.events);
            setLoadingLogs(false);
        }).catch((e) => {
            console.log(e);
        });
        setOpenJobLogs(true);
    };
    const handleClose = () => {
        setOpenJobLogs(false);
    };

    return (
        <React.Fragment>
            <Labeled label="AWX Job Link">
                <UrlField source="awx_jobs" target="_blank" label=""/>
            </Labeled>
            {record.awx_jobs !== '' ? <Box>
                <Button size="small" variant="contained" onClick={handleClickOpen}>
                  Show AWX Job Logs
                </Button>
            </Box> : null}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={openjoblogs}
                maxWidth={'md'}
              >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                  AWX Jobs Logs => {record.awx_jobs}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    { !loadinglogs ? logs.map((log, idx) => (
                        <p key={"logs-"+idx} style={pLogEvent} dangerouslySetInnerHTML={{__html: ansi.toHtml(log)}}></p>
                        ))
                        : <CircularProgress />
                    }
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </BootstrapDialog>
        </React.Fragment>

    )
}


const CircuitShow = (props) => {
    const record = useRecordContext();

    return (
      <Show title={<CircuitTitle />}>
        <TabbedShowLayout>
            <Tab label="circuit.form.summary">
                <TextField source="id" />
                <TextField source="leoid" />
                <ReferenceField label="Client" source="client" reference="companies" linkType={false}>
                    <TextField source="nom" />
                </ReferenceField>
                <TextField source="status" />
            </Tab>
            <Tab label="circuit.form.network">
                <ReferenceField label="Type" source="type" reference="typescircuit" sort={{field: 'name', order: 'ASC'}}>
                    <TextField source="name"/>
                </ReferenceField>
                { record && ( record.type === 1 || record.type === 6 ) &&
                    ( record.site !== -1 ? <ReferenceField label="Site" source="site" reference="sites" sort={{field: 'name', order: 'ASC'}}>
                        <TextField source="name"/>
                    </ReferenceField> :  <FormControl><TextFieldMaterial label="Site" value={"Not Defined"} /></FormControl>)
                }
                { record && record.type === 7 &&
                    <ReferenceField label="Collect L2TP" source="collect_l2tp" reference="collectsl2tp" sort={{field: 'name', order: 'ASC'}}>
                        <TextField source="name"/>
                    </ReferenceField>
                }
                { record && record.type === 8 &&
                    <ReferenceField label="Collect L2" source="collect_l2" reference="collectsl2" sort={{field: 'name', order: 'ASC'}}>
                        <TextField source="name"/>
                    </ReferenceField>
                }
                <TextField source="vlan_client" label="Vlan client"/>
                <TextField source="interco_routeur" label="Interco routeur"/>
                <TextField source="interco_cpe" label="Interco cpe"/>
                <TextField source="prefix_v6" label="Prefix v6"/>
                <TextField source="debit" label="Débit Mb/s"/>
                <ArrayField source="ips" >
                    <Datagrid>
                        <TextField source="display" />
                        <TextField source="family" />
                        <TextField source="status" />
                        <TextField source="description" />
                    </Datagrid>
                </ArrayField>
                <ArrayField source="prefixes">
                    <Datagrid>
                        <TextField source="display" />
                        <TextField source="family" />
                        <TextField source="status" />
                        <TextField source="description" />
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="circuit.form.miscellaneous">
                <AwxJobsComponent />
                <TextField source="comment" />
                <UrlField source="url_netbox" target="_blank"/>
            </Tab>
            <Tab label="circuit.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
      </Show>
    )
};

export default CircuitShow;
