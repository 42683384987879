import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import RouterCreate from './RouterCreate';
import RouterEdit from './RouterEdit';
import RouterList from './RouterList';
import RouterShow from './RouterShow';

export default {
    list: RouterList,
    create: RouterCreate,
    edit: RouterEdit,
    show: RouterShow,
    icon: SettingsEthernetIcon,
};
