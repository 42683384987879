import BookIcon from '@mui/icons-material/Book';
import RealmsCreate from './RealmsCreate';
import RealmsEdit from './RealmsEdit';
import RealmsList from './RealmsList';
import RealmsShow from './RealmsShow';

export default {
    list: RealmsList,
    create: RealmsCreate,
    edit: RealmsEdit,
    show: RealmsShow,
    icon: BookIcon,
};
