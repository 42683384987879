import React from "react";
import { Grid, Typography } from '@mui/material';
import {
    BooleanInput,
    Create,
    NumberInput,
    AutocompleteInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    SelectInput,
    TextInput,
    Toolbar,
    required, useNotify, useRedirect
} from 'react-admin';
import {useFormContext} from "react-hook-form";

const CpeCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/cpe', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const CpeCreate = () => {
    const state = {
        ipv4_address: '',
        ipv6_address: '',
        ipv6_prefix: '',
        realm: '',
    };

    return (
      <Create
        {...this.props} >
          <SimpleForm
              defaultValue={state}
              toolbar={<CpeCreateToolbar />}
              validate={values => {
                  const errors = {};
                  ['company', 'collecte'].forEach(field => {
                      if (!values[field]) {
                          errors[field] = ['Required field'];
                      }
                  });

                  return errors;
              }}
          >
              <SelectInput source="type" choices={[
                    { id: 'ADSL', name: 'ADSL' },
                    { id: 'SDSL', name: 'SDSL' },
                    { id: 'VDSL', name: 'VDSL' },
                    { id: 'FTTH', name: 'FTTH' },
                ]} />
              <ReferenceInput label="Company" source="company" reference="companies" sort={{field: 'nom', order: 'ASC'}}>
                  <AutocompleteInput optionText="nom"/>
              </ReferenceInput>
              <ReferenceInput label="Collecte" source="collect" reference="collectsl2tp" sort={{field: 'name', order: 'ASC'}}>
                  <AutocompleteInput optionText="name"/>
              </ReferenceInput>
              <TextInput source="ipv4_address" validate={required()}/>
              <TextInput source="ipv6_address" validate={required()}/>
              <TextInput source="ipv6_prefix" validate={required()}/>
              <Grid container>
                  <Grid item>
                    <TextInput source="username" validate={required()}/>
                  </Grid>
                  <Grid item style={{display: "grid", alignItems: "end"}}>
                      <Typography style={{height: "10px", paddingBottom: "25px", paddingLeft: "10px", paddingRight: "10px"}}>@</Typography>
                  </Grid>
                  <Grid item>
                      <ReferenceInput label="Realm" source="realm" reference="realms" sort={{field: 'domain', order: 'ASC'}} validate={required()}>
                          <AutocompleteInput optionText="domain"/>
                      </ReferenceInput>
                  </Grid>
              </Grid>
              <NumberInput source="bw_down"/>
              <NumberInput source="bw_up" />
              <BooleanInput source="enable" defaultValue />
              <TextInput source="comment" />
          </SimpleForm>
      </Create>
    )
};
export default CpeCreate;
