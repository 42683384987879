import React, {Fragment} from 'react';
import {
    Create,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    SaveButton,
    SimpleForm,
    TextInput,
    BooleanInput,
    FormDataConsumer,
    Toolbar,
    required, useNotify, useRedirect
} from 'react-admin';
import RealmsTitle from './RealmsTitle';
import IPRealmQuickCreateButton from "./IPRealmQuickCreateButton";
import {useFormContext} from "react-hook-form";

const IPRealmReferenceInput = props => (
  <Fragment>
    <ReferenceArrayInput {...props}>
      <AutocompleteArrayInput source="ips" optionText="ip" optionValue="id"/>
    </ReferenceArrayInput>
    <IPRealmQuickCreateButton />
  </Fragment>
);

const RealmsCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/realms', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const RealmsCreate = props => (
    <Create title={<RealmsTitle />} {...props}>
        <SimpleForm
            toolbar={<RealmsCreateToolbar />}
        >
            <TextInput source="domain" validate={required()} />
            <TextInput source="tunnelid"/>
            <IPRealmReferenceInput label="LNS IPs" source="ips" reference="ip_realm" sort={{field: 'ip', order: 'ASC'}} validate={required()} />
            <BooleanInput source="enabled" defaultValue />
            <BooleanInput source="remote" />
            <FormDataConsumer>
             {({ formData, ...rest }) => formData.remote &&
                 <ReferenceArrayInput label="Radius" source="radius_servers" reference="radiusrealm" sort={{field: 'addr', order: 'ASC'}}>
                    <AutocompleteArrayInput source="radiusrealm" optionText="addr" optionValue="id"/>
                 </ReferenceArrayInput>
             }
            </FormDataConsumer>
            <TextInput multiline source="comment" />
        </SimpleForm>
    </Create>
);

export default RealmsCreate;
