import LinkIcon from '@mui/icons-material/Link';
import TransitGWCreate from './TransitGWCreate';
import TransitGWEdit from './TransitGWEdit';
import TransitGWList from './TransitGWList';
import TransitGWShow from './TransitGWShow';

export default {
    list: TransitGWList,
    create: TransitGWCreate,
    edit: TransitGWEdit,
    show: TransitGWShow,
    icon: LinkIcon,
};
