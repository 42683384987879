/* eslint react/jsx-key: off */
import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    ReferenceInput,
    SelectInput,
    required
} from 'react-admin';
import UserTitle from './UserTitle';

const UserEdit = ({ permissions, ...props }) => (
    <Edit title={<UserTitle />} {...props}>
        <TabbedForm defaultValue={{ role: 'user' }}>
            <FormTab label="user.form.summary">
                {permissions === 'Admin' && <TextInput disabled source="id" />}
                {permissions === 'Admin' && <TextInput source="email" validate={required()} />}
                <TextInput source="first_name" validate={required()} />
                <TextInput source="last_name" validate={required()} />
                {permissions === 'Admin' && <BooleanInput source="is_active" />}
            </FormTab>
            {permissions === 'Admin' && (
                <FormTab label="user.form.security">
                    <ReferenceInput source="groups" reference="groups">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <BooleanInput source="reset_password" />
                </FormTab>
            )}
        </TabbedForm>
    </Edit>
);

export default UserEdit;
