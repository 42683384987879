import { useRecordContext } from 'react-admin';
import {Typography} from "@mui/material";

export const BandwidthField = ({ source }) => {
    const record = useRecordContext();

    let bytes = record && record[source] ? record[source] : 0;

    let s = ['ko', 'Mo', 'Go', 'To', 'Po'];
    let e = Math.floor(Math.log(bytes) / Math.log(1000));
    let human_readable =  (bytes / Math.pow(1000, e)).toFixed(0) + " " + s[e];

    return (<Typography component="span" variant="body2">
        {human_readable}
    </Typography>);
};