import BookIcon from '@mui/icons-material/Book';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BuildIcon from '@mui/icons-material/Build';
import React, { Fragment } from 'react';
import {
    BulkDeleteButton,
    Datagrid,
    EditButton,
    BooleanField,
    ReferenceField,
    Filter,
    List,
    ShowButton,
    SimpleList,
    TextField,
    TextInput,
    SelectInput,
    Button,
    useNotify,
    useRecordContext,
    useDataProvider, usePermissions, ReferenceArrayInput, AutocompleteArrayInput
} from 'react-admin';
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";

export const PostIcon = BookIcon;

const CircuitFilter = props => (
    <Filter {...props}>
        <TextInput
            label="circuit.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
        <ReferenceArrayInput source="type" reference="typescircuit">
            <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="collect_l2" reference="collectsl2">
            <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput source="collect_l2tp" reference="collectsl2tp">
            <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput source="status" choices={[
            { id: 'new', name: 'New' },
            { id: 'active', name: 'Active' },
            { id: 'offline', name: 'Offline' },
            { id: 'planned', name: 'Planned' },
            { id: 'provisioning', name: 'Provisinning' },
            { id: 'deprovisioning', name: 'Deprovisioning' },
            { id: 'decomissionned', name: 'Decomissionned' },
            { id: 'provisionningerror', name: 'Provisionning Error' },
            { id: 'conferror', name: 'Configuration Error' },
        ]} />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const DeployInfraButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    if (!record) return null;

    return  <Button label={"Deploy Infra"}
                    disabled={record.status !== 'planned' || record.collect_l2 === null}
                    onClick={e => {
                e.stopPropagation();
                dataProvider.deployInfraCircuit(record.id).then(()=>{
                    notify('Infra Deployment launched', {type: 'success'});
                }).catch((e) => {
                    notify('Error: infra deployment not launched', {type: 'warning'})
                });
            }}>
                <BuildIcon/>
            </Button>
};

const CircuitListBulkActions = props => (
    <Fragment>
        <BulkDeleteButton {...props}/>
    </Fragment>
);

const CircuitList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {permissions} = usePermissions();
    const styles = useStyles();

    return (
    <List
        {...props}
        filters={<CircuitFilter />}
        sort={{ field: 'id', order: 'DESC' }}
    >
        {isSmall ? (
                <SimpleList
                    primaryText={record => record.company}
                    secondaryText={record => `${record.leoid}`}
                    tertiaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ) : (
                <Datagrid bulkActionButtons={<CircuitListBulkActions />}>
                    <TextField source="id" />
                    <TextField source="leoid" cellClassName={styles.leoid} />
                    <ReferenceField label="Type" source="type" reference="typescircuit" sortBy={"type__name"} sort={{field: 'name', order: 'ASC'}}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField label="Client" source="client" reference="companies" sortBy="client" linkType={false}>
                        <TextField source="nom" />
                    </ReferenceField>
                    <ReferenceField label="Collecte L2TP" source="collect_l2tp" reference="collectsl2tp" allowEmpty={true} linkType={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Collecte L2" source="collect_l2" reference="collectsl2" allowEmpty={true} linkType={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="vlan_client" label="Vlan client" />
                    <TextField source="status" cellClassName={styles.status} />
                    <BooleanField source="cpe" />
                    <ListActionsToolbar>
                        <EditButton />
                        <ShowButton />
                        {['admin','deploy'].includes(permissions) ? <DeployInfraButton/> : <Fragment/>}
                    </ListActionsToolbar>
                </Datagrid>
            )}
    </List>)
};

export default CircuitList;
