import React, { useState, useEffect } from "react";
import {
    Create,
    TabbedForm,
    FormTab,
    BooleanInput,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    RadioButtonGroupInput,
    required,
    useDataProvider
} from 'react-admin';
import ConfigsTitle from './ConfigsTitle';
import { RichTextInput } from 'ra-input-rich-text';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    richTextInput: {
        '& .ql-toolbar': {
            height: '0px'
        },
        '& .ql-editor': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
            maxHeight: '300px',
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
            '&::after': {
                backgroundColor: 'rgba(0, 0, 0, 0.12)',
            },
            '& p': {
                marginBottom: '2px !important'
            }
        },
    },
});

const ConfigsCreate = (props) => {
    const [genMode, setGenMode] = useState('auto');
    const [showWifiTab, setShowWifiTab] = useState(false);
    const [showIntersiteTab, setShowIntersiteTab] = useState(false);
    const [showL2tpTab, setShowL2tpTab] = useState(false);
    const [listeCpe, setListeCpe] = useState([]);
    const [listeConf, setListeConf] = useState([]);
    const dataProvider = useDataProvider();
    const styles = useStyles();

    const parseChoices = (choices) => {
        let selectChoices = [];
        choices.forEach((res) => {
            selectChoices.push({id: res.value, name: res.display_name})
        });
        return selectChoices;
    }

    useEffect(()=>{
        dataProvider.getConfigOptions().then(response => {
            if(listeCpe.length===0) {
                const {data} = response;
                let newrecord = data.actions.POST;

                setListeCpe(parseChoices(newrecord.type_cpe.choices));
                setListeConf(parseChoices(newrecord.conf.choices));
            }
        }).catch((e) => {
            console.log(e);
        });
    })

    return (
        <Create title={<ConfigsTitle/>} {...props}>
            <TabbedForm>
                <FormTab label="configs.form.summary">
                    <ReferenceInput label="Circuit" source="circuit" reference="circuits"
                                    sort={{field: 'leoid', order: 'ASC'}} validate={required()}>
                        <AutocompleteInput optionText="leoid"/>
                    </ReferenceInput>
                    <RadioButtonGroupInput source="genmode"  defaultValue="auto"
                                           onChange={(event, id) => setGenMode(id)} choices={[
                        { id: 'manual', name: 'Manual' },
                        { id: 'auto', name: 'Automatic' },
                    ]} />
                    <BooleanInput source="enabled"/>
                </FormTab>
                {genMode === 'auto' &&
                        <FormTab label="configs.form.variables" path={"variables"}>
                            <SelectInput source="type_cpe" choices={listeCpe}/>
                            <SelectInput source="conf" choices={listeConf}/>
                            <SelectInput source="prefix_v4" choices={[
                                { id: 32, name: '1 IP dispo : /32' },
                                { id: 30, name: '2 IP dispo : /30' },
                                { id: 29, name: '4 IP dispo : /29' },
                                { id: 28, name: '14 IP dispo : /28' },
                                { id: 27, name: '30 IP dispo : /27' },
                                { id: 26, name: '60 IP dispo : /26' },
                            ]} />
                            <TextInput source={"id_operator"}/>
                            <BooleanInput source="has_wifi" onChange={() => setShowWifiTab(!showWifiTab)}/>
                            <BooleanInput source="has_intersite" onChange={(e) => setShowIntersiteTab(!showIntersiteTab)}/>
                            <BooleanInput source="has_l2tp" onChange={(e) => setShowL2tpTab(!showL2tpTab)}/>
                        </FormTab>
                }
                {genMode === 'auto' && <FormTab label="configs.form.data" path={"data"}>
                    <BooleanInput source={"dhcp_data"} />
                    <TextInput source={"lan_data_client"} />
                    <TextInput source={"cpe_data_client"} />
                </FormTab>}
                {genMode === 'auto' && showWifiTab &&
                    <FormTab label="configs.form.wifi" path={"wifi"}>
                        <TextInput source={"wifi_ssid"} />
                        <TextInput source={"wifi_passphrase"} />
                    </FormTab>
                }
                {genMode === 'auto' && showL2tpTab &&
                    <FormTab label="configs.form.l2tp" path={"l2tp"}>
                        <TextInput source={"ppp_local_address"} />
                        <TextInput source={"ppp_remote_address"} />
                        <TextInput source={"vpn_user"} />
                    </FormTab>
                }
                {genMode === 'auto' && showIntersiteTab &&
                    <FormTab label="configs.form.intersite" path={"intersite"}>
                         <ReferenceInput label="VRF Intersite" source="vrf_intersite" reference="clientvrfs"
                                    sort={{field: 'name', order: 'ASC'}}>
                            <AutocompleteInput optionText="name"/>
                        </ReferenceInput>
                        <NumberInput source={"qos_intersite"} />
                    </FormTab>
                }
                {genMode === 'manual' &&
                    <FormTab label="configs.form.conf" path={"conf"}>
                        <RichTextInput className={styles.richTextInput} source={"generated_configuration"} toolbar={[]}/>
                    </FormTab>
                }
            </TabbedForm>
        </Create>
    );
};

export default ConfigsCreate;
