import React, { useEffect, useState } from "react";
import { Grid, Typography } from '@mui/material';
import {
    BooleanInput,
    Create,
    NumberInput,
    AutocompleteInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    SelectInput,
    TextInput,
    Toolbar,
    required, useNotify, useRedirect, useResourceContext, useDataProvider
} from 'react-admin';
import {useFormContext} from "react-hook-form";

const L2tpCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/l2tp', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const L2tpCreate = (props) => {
    const [record, setRecord] = useState({
        ipv4_address: '',
        ipv6_addres: '',
        ipv6_prefix: ''
    })
    const [fetched, setFetched] = useState(false);
    const resource = useResourceContext();
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getL2tpNextIP().then(response => {
            let data = response.data.results[0];
            setRecord({
                ipv4_address: data.ipv4_address,
                ipv6_address: data.ipv6_address,
                ipv6_prefix: data.ipv6_prefix
            });
            setFetched(true);
        }).catch((e) => {
            console.log(e);
        });

    }, [fetched, resource]);

    const createProps = { ...props, record }

    return (
      <Create {...createProps}>
          <SimpleForm
              defaultValues={{}}
              toolbar={<L2tpCreateToolbar />}
              validate={values => {
                  const errors = {};
                  ['type', 'company', 'collect', 'username', 'realm'].forEach(field => {
                      if (!values[field]) {
                          errors[field] = ['Required field'];
                      }
                      if (field === 'username' && values[field] && !values[field].match(/^[a-zA-Z0-9\-\._@]*$/g)) {
                          errors[field] = ['Username bad format']
                      }
                  });

                  return errors;
              }}
          >
              <SelectInput source="type" validate={required()} choices={[
                    { id: 'ADSL', name: 'ADSL' },
                    { id: 'VDSL', name: 'VDSL' },
                    { id: 'FTTH', name: 'FTTH' },
                    { id: 'Mobile', name: 'Mobile' },
                ]} />
              <ReferenceInput label="Company" source="company" reference="companies" sort={{field: 'nom', order: 'ASC'}} validate={required()} >
                  <AutocompleteInput optionText="nom" validate={required()} />
              </ReferenceInput>
              <ReferenceInput label="Collecte" source="collect" reference="collectsl2tp" sort={{field: 'name', order: 'ASC'}} validate={required()}>
                  <AutocompleteInput optionText="name" validate={required()}/>
              </ReferenceInput>
              <TextInput source="ipv4_address" validate={required()}/>
              <TextInput source="ipv6_address" validate={required()}/>
              <TextInput source="ipv6_prefix" validate={required()}/>
              <Grid container>
                  <Grid item>
                    <TextInput source="username" validate={required()}/>
                  </Grid>
                  <Grid item style={{display: "grid", alignItems: "end"}}>
                      <Typography style={{height: "10px", paddingBottom: "25px", paddingLeft: "10px", paddingRight: "10px"}}>@</Typography>
                  </Grid>
                  <Grid item>
                      <ReferenceInput label="Realm" source="realm" reference="realms" sort={{field: 'domain', order: 'ASC'}} validate={required()}>
                          <AutocompleteInput optionText="domain" validate={required()}/>
                      </ReferenceInput>
                  </Grid>
              </Grid>
              <NumberInput source="bw_down" defaultValue={1000000}/>
              <NumberInput source="bw_up" defaultValue={1000000}/>
              <BooleanInput source="enable" defaultValue />
              <TextInput source="comment" />
          </SimpleForm>
      </Create>
    )
}

export default L2tpCreate;
