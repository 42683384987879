import PeopleIcon from '@mui/icons-material/People';
import SearchIcon from '@mui/icons-material/Search';
import {InputAdornment, useMediaQuery} from '@mui/material';
import React from 'react';
import {
    Datagrid,
    EditButton,
    Filter,
    List,
    ShowButton,
    SimpleList,
    BooleanField,
    TextField,
    TextInput, WithRecord,
} from 'react-admin';
import UserTitle from './UserTitle';
export const UserIcon = PeopleIcon;

const UserFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput
            label="user.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
        <TextInput source="email" />
        {permissions === 'Admin' ? <TextInput source="role" /> : null}
    </Filter>
);

const UserList = ({ permissions, ...props }) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
        {...props}
        title={<UserTitle />}
        filters={<UserFilter permissions={permissions} />}
        sort={{ field: 'email', order: 'ASC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record =>
                        permissions === 'Admin' ? record.role : null}
                />
            ):(
                <Datagrid hover={false}>
                    <TextField source="id" />
                    <TextField source="email" />
                    <TextField source="first_name" />
                    <TextField source="last_name" />
                    {permissions === 'Admin' && <TextField source="role" />}
                    <BooleanField source="sso_account"/>
                    <BooleanField source="is_active" />
                    <WithRecord render={record => !record.sso_account ? <EditButton /> : <React.Fragment/>} />
                    <ShowButton />
                </Datagrid>
            )}
    </List>
    )
};

export default UserList;
