import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin';
import TypeCircuitTitle from './TypesCircuitTitle';

const TypesCircuitEdit = props => (
    <Edit title={<TypeCircuitTitle />} {...props}>
         <TabbedForm>
            <FormTab label="typecircuit.form.summary">
                <TextInput disabled source="id" />
                <TextInput source="name" validate={required()} />
                <TextInput source="netbox_id" validate={required()} />
                <TextInput source="base_leoid" validate={required()} />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default TypesCircuitEdit;
