import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import {
    BooleanField,
    BulkDeleteButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    NumberField,
    ShowButton,
    FunctionField,
    SimpleList,
    TextField,
    TextInput,
    usePermissions
} from 'react-admin';
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";
import {BandwidthField} from "../../components/Fields/BandwidthField";

const TransitGWFilter = props => (
    <Filter {...props}>
        <TextInput
            label="transitgw.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const TransitGWListBulkActions = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const TransitGWList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {permissions} = usePermissions();
    const styles = useStyles();
    return (
        <List
        {...props}
        bulkActionButtons={<TransitGWListBulkActions />}
        filters={<TransitGWFilter />}
        sort={{ field: 'id', order: 'DESC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.company_name}
                    secondaryText={record => `${record.leoid}`}
                    tertiaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ):(
                <Datagrid>
                    <TextField source="id" />
                    <FunctionField
                        label="Name"
                        cellClassName={styles.company}
                        render={record => `${record.company_name}`}
                        sortBy={"company"}
                    />;
                    <TextField source="leoid" cellClassName={styles.leoid} sortBy={"circuit__leoid"}/>
                    <TextField source="circuit_type" sortBy={"circuit__type__name"}/>
                    <TextField source="ipv4_address" cellClassName={styles.ipv4_address} />
                    <TextField source="ipv6_address" cellClassName={styles.ipv6_address} />
                    <TextField source="comment" cellClassName={styles.comment} />
                    <BandwidthField source="commit" />
                    <BandwidthField source="port" />
                    <BooleanField
                        source="enabled"
                        label="enabled"
                        sortable={false}
                    />
                    <ListActionsToolbar>
                        {permissions !== 'Viewer' ? <EditButton /> : <Fragment/> }
                        <ShowButton />
                    </ListActionsToolbar>
                </Datagrid>
            )}
    </List>
    )
};

export default TransitGWList;
