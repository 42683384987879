import React from 'react';
import {useRecordContext, useTranslate} from 'react-admin';

export default () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return (
        <span>
            {record ? translate('transitgw.edit.title', { title: record.circuit_type + ' ' + record.leoid }) : ''}
        </span>
    )
};