import React from 'react';
import {Edit, TextInput, SimpleForm, Toolbar, SaveButton, email, required, useGetIdentity, useGetOne, WithRecord} from 'react-admin';

const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ProfileEditForm = ({ identity, ...props}) => {
    const { data: user, isLoading } = useGetOne('users', { id: identity.id });

    if (isLoading) return <>Loading...</>;
    if (!user) return null;

    return (
        <Edit
            redirect={false} // I don't need any redirection here, there's no list page
            resource="users"
            id={user.id}
            basepath="/my-profile"
            title="My Profile"
            {...props}
        >
            <SimpleForm toolbar={<UserEditToolbar />}>
                <TextInput source="email" validate={[required(), email()]} disabled={user.sso_account}/>
                <TextInput source="first_name" validate={required()}  disabled={user.sso_account}/>
                <TextInput source="last_name" validate={required()}  disabled={user.sso_account}/>
                <WithRecord render={record => !record.sso_account ? <TextInput source="password" type="password" validate={required()} /> : <React.Fragment/>} />
            </SimpleForm>
        </Edit>
    )
}

const ProfileEdit = ({ staticContext, ...props }) => {
    const { data: identity, isLoading: identityLoading } = useGetIdentity();

    if (identityLoading) return <>Loading...</>;

    return (
        <ProfileEditForm identity={identity} />
    );
};

export default ProfileEdit;
