import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    TextField
} from 'react-admin';
import IPRealmTitle from './IPRealmTitle';

const IPRealmShow = () => (
    <Show title={<IPRealmTitle />}>
        <TabbedShowLayout>
            <Tab label="iprealm.form.summary">
                <TextField source="id" />
                <TextField source="ip" />
            </Tab>
            <Tab label="iprealm.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default IPRealmShow;
