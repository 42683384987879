import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    ReferenceArrayField,
    SingleFieldList,
    BooleanField,
    DateField,
    TextField
} from 'react-admin';
import RealmsTitle from './RealmsTitle';


const useStyles = makeStyles(theme => ({
    textip: {
        marginLeft: '2px',
        marginRight: '2px',
        backgroundColor: '#e0e0e0',
        borderRadius: '16px',
        paddingLeft: '4px',
        paddingRight: '4px'

    },
}));

const RealmsShow = () => {
    const classes = useStyles();
    return (
        <Show title={<RealmsTitle />}>
        <TabbedShowLayout>
            <Tab label="realm.form.summary">
                <TextField source="id" />
                <TextField source="domain" />
                <ReferenceArrayField label="IPs" source="ips" reference="ip_realm" linkType={false}>
                    <SingleFieldList linkType="show">
                        <TextField className={classes.textip} source="ip" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField source="enabled" />
            </Tab>
            <Tab label="realm.form.remote">
                <BooleanField source="remote" />
                <ReferenceArrayField label="Radius" source="radius_servers" reference="radiusrealm" linkType={false}>
                    <SingleFieldList linkType="show">
                        <TextField className={classes.textip} source="addr" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </Tab>
            <Tab label="realm.form.miscellaneous">
                <TextField source="comment" />
            </Tab>
            <Tab label="realm.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
    )
};

export default RealmsShow;
