import React, {Fragment, useEffect, useState} from "react";
import {Grid, Typography} from '@mui/material';
import {
    BooleanInput,
    Create,
    NumberInput,
    AutocompleteInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    SelectInput,
    TextInput,
    Toolbar,
    required, useNotify, useRedirect, useResourceContext, useDataProvider, TabbedForm, FormTab, RadioButtonGroupInput
} from 'react-admin';
import {useFormContext} from "react-hook-form";

const TransitGWCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const {reset} = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/transitgw', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }
                }
                }
                variant="text"
            />
        </Toolbar>
    )
};

const TransitGWCreate = (props) => {
    const [record, setRecord] = useState({
        ipv4_address: ''
    })
    const [portType, setPortType] = useState('Gateway');

    const createProps = {...props, record}

    useEffect(()=>{
        console.log(portType);
    }, [portType])

    return (
        <Create {...createProps}>
            <TabbedForm
                defaultValues={{}}
                toolbar={<TransitGWCreateToolbar/>}
                validate={values => {
                    const errors = {};
                    ['company', 'type', 'commit'].forEach(field => {
                        if (!values[field]) {
                            errors[field] = ['Required field'];
                        }
                    });

                    return errors;
                }}
            >
                <FormTab label="transitgw.form.summary">
                    <RadioButtonGroupInput source="type"  defaultValue="Gateway"
                                           onChange={(event, id) => setPortType(id)} choices={[
                       { id: 'Gateway', name: 'Gateway' },
                        { id: 'Transit', name: 'Transit' },
                    ]} />
                    <ReferenceInput label="Company" source="company" reference="companies"
                                    sort={{field: 'nom', order: 'ASC'}}>
                        <AutocompleteInput optionText="nom"/>
                    </ReferenceInput>
                    <SelectInput source={"commit"} choices={[
                        {id: 100000, name: '100M'},
                        {id: 200000, name: '200M'},
                        {id: 300000, name: '300M'},
                        {id: 500000, name: '500M'},
                        {id: 700000, name: '700M'},
                        {id: 1000000, name: '1G'},
                        {id: 2000000, name: '2G'},
                        {id: 3000000, name: '3G'},
                        {id: 5000000, name: '5G'},
                        {id: 7000000, name: '7G'},
                        {id: 10000000, name: '10G'}
                    ]}/>
                    <SelectInput source={"port"} choices={[
                        {id: 10000000, name: '10G'},
                        {id: 1000000, name: '1G'},
                    ]}/>
                    <BooleanInput source="enable" defaultValue/>
                    <TextInput source="comment"/>
                </FormTab>
                <FormTab label={"transitgw.form.network"}>
                    <ReferenceInput label="Router" source="collect" reference="collectsl2" sort={{field: 'name', order: 'ASC'}}>
                        <AutocompleteInput optionText="name"/>
                    </ReferenceInput>
                    <TextInput source="ipv4_address" validate={required()}/>
                    <TextInput source="ipv6_address"/>
                    {portType === 'Gateway' && <TextInput source="ipv6_prefix"/>}
                </FormTab>
                {portType === 'Transit' && <FormTab label="transitgw.form.transit">
                    <NumberInput source="asn" label="ASN"/>
                    <BooleanInput source="originate" defaultValue={false} defaultChecked={false}/>
                    <TextInput source="import_policy"/>
                    <TextInput source="export_policy"/>
                    <NumberInput source="peering_manager_connid"/>
                    <BooleanInput source="bfd" defaultValue={false} defaultChecked={false}/>
                    <TextInput source="password"/>
                </FormTab>
                }
            </TabbedForm>
        </Create>
    )
}

export default TransitGWCreate;
