import React from 'react';
import {useRecordContext, useTranslate} from 'react-admin';

export default () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return (
        <span>
            {record ? translate('router.edit.title', { title: record.name }) : ''}
        </span>
    )
};