import React, {useState, Fragment} from 'react';
import {DashboardMenuItem, Menu, MenuItemLink, usePermissions, useSidebarState} from 'react-admin';
import {
    Divider
} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import DomainIcon from '@mui/icons-material/Domain';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import BookIcon from '@mui/icons-material/Book';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import SimCardIcon from '@mui/icons-material/SimCard';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import RepeatIcon from '@mui/icons-material/Repeat';
import BuildIcon from '@mui/icons-material/Build';
import RouterIcon from '@mui/icons-material/Router';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SubMenu from './SubMenu';

import circuits from '../../pages/circuits';
import collectsl2 from '../../pages/collectsl2';
import collectsl2tp from '../../pages/collectsl2tp';
import l2tp from '../../pages/l2tp';
import transitgw from '../../pages/transitgw';

const ProvisionMenu = (props) => {
    const [open] = useSidebarState();
    const { permissions } = usePermissions();

    const initialExpansionState = {
        menuCollectes: false,
        menuRealm: false,
        menuAdmin: false
    };

    const [state, setState] = useState(initialExpansionState);

    const handleToggle = (parent) => {
        setState(state => ({ [parent]: !state[parent] }));
    };

    return (
        <Menu {...props}>
            <DashboardMenuItem />
            <Divider />
            <MenuItemLink
                key={`cpe`}
                to={`/cpe`}
                primaryText={'CPE'}
                leftIcon={
                <NaturePeopleIcon/>
                }
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
            />
            <Divider />
            <MenuItemLink
                key={`configs`}
                to={`/configs`}
                primaryText={'Config'}
                leftIcon={
                    <BuildIcon/>
                }
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                key={`l2tp`}
                to={`/l2tp`}
                primaryText={'L2TP'}
                leftIcon={
                    <l2tp.icon/>
                }
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                key={`circuits`}
                to={`/circuits`}
                primaryText={'Circuits'}
                leftIcon={
                    <circuits.icon/>
                }
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                key={`transitgw`}
                to={`/transitgw`}
                primaryText={'Transit & Gateway'}
                leftIcon={
                    <transitgw.icon/>
                }
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
            />
            <Divider />
            <SubMenu
                handleToggle={() => handleToggle('menuCollectes')}
                isOpen={state.menuCollectes}
                sidebarIsOpen={open}
                name="Collectes"
                icon={<BookIcon/>}
            >
                 <MenuItemLink
                        key={`collectsl2`}
                        to={`/collectsl2`}
                        primaryText={'Collectes L2'}
                        leftIcon={
                            <collectsl2.icon/>
                        }
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                 <MenuItemLink
                        key={`collectsl2tp`}
                        to={`/collectsl2tp`}
                        primaryText={'Collectes L2TP'}
                        leftIcon={
                            <collectsl2tp.icon/>
                        }
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                 <MenuItemLink
                        key={`routers`}
                        to={`/routers`}
                        primaryText={'Routers'}
                        leftIcon={
                             <RouterIcon/>
                        }
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuRealm')}
                isOpen={state.menuRealm}
                sidebarIsOpen={open}
                name="Realm"
                icon={<DomainIcon/>}
            >
                <MenuItemLink
                    key={`realms`}
                    to={`/realms`}
                    primaryText="Realm L2TP"
                    leftIcon={<VpnLockIcon/>}
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                />
                { permissions === 'admin' ? <MenuItemLink
                    key={`ip_realm`}
                    to={`/ip_realm`}
                    primaryText="IP for Realm"
                    leftIcon={<SimCardIcon/>}
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                /> : <Fragment/>}
                { permissions === 'admin' ? <MenuItemLink
                    key={`radiusrealm`}
                    to={`/radiusrealm`}
                    primaryText="Radius for Realm"
                    leftIcon={<SupervisorAccountIcon/>}
                    onClick={props.onMenuClick}
                    sidebarIsOpen={open}
                /> : <Fragment/>}
            </SubMenu>
            <Divider />
            { permissions === 'admin' ?
                [<SubMenu
                    handleToggle={() => handleToggle('menuAdmin')}
                    isOpen={state.menuAdmin}
                    sidebarIsOpen={open}
                    name="Admin"
                    icon={<VerifiedUserIcon/>}
                    key={"1"}
                >
                    <MenuItemLink
                        key={`typescircuit`}
                        to={`/typescircuit`}
                        primaryText="Type Circuits"
                        leftIcon={<TimelineIcon/>}
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                    <MenuItemLink
                        key={`clientvrfs`}
                        to={`/clientvrfs`}
                        primaryText="VRF Clients"
                        leftIcon={<CallSplitIcon/>}
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                    <MenuItemLink
                        key={`devicetypes`}
                        to={`/devicetypes`}
                        primaryText={'Type Device'}
                        leftIcon={
                        <NaturePeopleIcon/>
                        }
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                    <MenuItemLink
                        key={`users`}
                        to={`/users`}
                        primaryText="Users"
                        leftIcon={<PeopleIcon/>}
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                </SubMenu>,
                    <Divider key={"2"}/>
                    ] : []
                }
            <MenuItemLink
                key={`my-profile`}
                to={`/my-profile`}
                primaryText="My Profile"
                leftIcon={<SettingsIcon/>}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
            />
        </Menu>)
};

export default ProvisionMenu;