import CallSplitIcon from '@mui/icons-material/CallSplit';
import RadiusRealmCreate from './RadiusRealmCreate';
import RadiusRealmEdit from './RadiusRealmEdit';
import RadiusRealmList from './RadiusRealmList';
import RadiusRealmShow from './RadiusRealmShow';

export default {
    list: RadiusRealmList,
    create: RadiusRealmCreate,
    edit: RadiusRealmEdit,
    show: RadiusRealmShow,
    icon: CallSplitIcon,
};
