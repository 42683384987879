import React from 'react';
import {
    Create,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    SelectInput, useNotify, useRedirect
} from 'react-admin';
import DeviceTypeTitle from './DeviceTypeTitle';
import {useFormContext} from "react-hook-form";

const DeviceTypeCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/devicetypes', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const DeviceTypeCreate = props => (
    <Create title={<DeviceTypeTitle />} {...props}>
        <SimpleForm
            toolbar={<DeviceTypeCreateToolbar />}
        >
            <TextInput source="model" validate={required()} />
            <TextInput source="slug" validate={required()} />
            <TextInput source="base_leoid" validate={required()} />
            <TextInput source="comment" label="Description" />
            <SelectInput source="id_manufacturer" label="Constructeur" validate={required()} choices={[
                { id: 9, name: 'Mikrotik' },
                { id: 35, name: 'Fortinet' },
            ]} />
        </SimpleForm>
    </Create>
);
export default DeviceTypeCreate;
