import React from 'react';
import {
    BooleanField,
    ReferenceField,
    DateField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import RouterTitle from './RouterTitle';

const RouterShow = () => (
    <Show title={<RouterTitle />}>
        <TabbedShowLayout>
            <Tab label="Summary">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="ipv4_address" label="IP Loopback 0"/>
                <BooleanField source="enabled" />
            </Tab>
            <Tab label="Miscellaneous">
                <TextField source="comment" />
                <TextField source="netbox_id" />
            </Tab>
            <Tab label="Object infos">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default RouterShow;
