import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    TextField,
    UrlField
} from 'react-admin';
import DeviceTypeTitle from './DeviceTypeTitle';

const DeviceTypeShow = () => (
    <Show title={<DeviceTypeTitle />}>
        <TabbedShowLayout>
            <Tab label="summary">
                <TextField source="id" />
                <TextField source="model" label="Modèle du CPE"/>
                <TextField source="base_leoid" label="Base du LEOID" />
            </Tab>
            <Tab label="miscellaneous">
                <TextField source="comment" />
                <UrlField source="url_netbox" target="_blank"/>
                <TextField source="id_netbox" />
            </Tab>
            <Tab label="objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default DeviceTypeShow;
