/* eslint react/jsx-key: off */
import React from 'react';
import {
    Create,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    email
} from 'react-admin';
import UserTitle from './UserTitle';

const UserCreateToolbar = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton
            label="user.action.save_and_show"
            redirect="show"
            submitOnEnter={true}
        />
        {permissions === 'Admin' && (
            <SaveButton
                label="user.action.save_and_add"
                redirect={false}
                submitOnEnter={false}
                variant="flat"
            />
        )}
    </Toolbar>
);

const UserCreate = ({ permissions, ...props }) => (
    <Create title={<UserTitle />} {...props}>
        <SimpleForm
            toolbar={<UserCreateToolbar permissions={permissions} />}
            defaultValue={{ role: 'User', password: '##GENERATE##' }}
        >
            <TextInput source="email" validate={[required(), email()]} />
            <TextInput source="first_name" />
            <TextInput source="last_name" />
            {permissions === 'Admin' && (
                <ReferenceInput source="groups" reference="groups">
                    <SelectInput optionText="name" />
                </ReferenceInput>
            )}
            <BooleanInput source="is_superuser" />
            <BooleanInput source="is_active" />
        </SimpleForm>
    </Create>
);

export default UserCreate;
