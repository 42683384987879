import CallSplitIcon from '@mui/icons-material/CallSplit';
import DeviceTypeCreate from './DeviceTypeCreate';
import DeviceTypeEdit from './DeviceTypeEdit';
import DeviceTypeList from './DeviceTypeList';
import DeviceTypeShow from './DeviceTypeShow';

export default {
    list: DeviceTypeList,
    create: DeviceTypeCreate,
    edit: DeviceTypeEdit,
    show: DeviceTypeShow,
    icon: CallSplitIcon,
};
