import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import CpeCreate from './CpeCreate';
import CpeEdit from './CpeEdit';
import CpeList from './CpeList';
import CpeShow from './CpeShow';

export default {
    list: CpeList,
    create: CpeCreate,
    edit: CpeEdit,
    show: CpeShow,
    icon: SettingsEthernetIcon,
};
