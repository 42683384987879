import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    TextField
} from 'react-admin';
import TypeCircuitTitle from './TypesCircuitTitle';

const TypesCircuitShow = () => (
    <Show title={<TypeCircuitTitle />}>
        <TabbedShowLayout>
            <Tab label="typecircuit.form.summary">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="netbox_id" />
                <TextField source="base_leoid" />
            </Tab>
            <Tab label="typecircuit.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default TypesCircuitShow;
