import React, { Fragment, useState } from 'react';
import {
    useNotify,
    useListContext,
    Button,
    useDataProvider
} from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@mui/material';
import { Typography } from "../../components/Wrappers";

const UpgradeButton = () => {
    const notify = useNotify();
    const { selectedIds } = useListContext();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const dataProvider = useDataProvider();

    const handleDialogClose = () => setOpen(false);

    const handleChange = (event, value) => {
        if(value !== ''){
            setDisabledButton(false);
        }
        setValue(value);
    };

    const handleClick = () => {
        dataProvider.getAvailableUpgradeCpeAcs().then(response => {
            setOptions(response.data);
        }).catch(() => {});
        setOpen(true);
    };

    const handleConfirm = () => {
      selectedIds.forEach(id => {
          dataProvider.launchUpgradeCpeAcs(id, value).then(() => {
            notify('Upgrade launched');
          }).catch((e) => {
            notify('Error: Upgrade not launched', 'warning')
          });
      });
      setOpen(false);
    };

    return (
        <Fragment>
            <Button label="Upgrade" onClick={handleClick}>
                <GetAppIcon/>
            </Button>
            <Dialog
              disableBackdropClick
              disableEscapeKeyDown
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={open}
            >
              <DialogTitle id="confirmation-dialog-title">Upgrade Firmware</DialogTitle>
              <DialogContent>
                  <Typography variant="body2">
                    Please select Firmware Version
                  </Typography>
                <RadioGroup
                  aria-label="Ringtone"
                  name="ringtone"
                  value={value}
                  onChange={handleChange}
                >
                  { options && options.length > 0 && options.map(option => (
                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                  ))}
                </RadioGroup>
              </DialogContent>
              <DialogActions>
                <Button label="Cancel" onClick={handleDialogClose} />
                  { options && options.length > 0 && <Button disabled={disabledButton} label="OK" onClick={handleConfirm}/> }
              </DialogActions>
            </Dialog>
          </Fragment>
    );
}

export default UpgradeButton;