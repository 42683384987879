import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    TextField
} from 'react-admin';
import CollectL2Title from './CollectL2Title';

const CollectL2Show = () => (
    <Show title={<CollectL2Title />}>
        <TabbedShowLayout>
            <Tab label="collectl2.form.summary">
                <TextField source="id" />
                <TextField source="name" />
                <ReferenceField label="Site" source="site" reference="sites" linkType={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Router" source="router" reference="routers" linkType={false}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="interface" />
            </Tab>
            <Tab label="collectl2.form.miscellaneous">
                <TextField source="comment" />
            </Tab>
            <Tab label="collectl2.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default CollectL2Show;
