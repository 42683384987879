import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin';
import DeviceTypeTitle from './DeviceTypeTitle';

const DeviceTypeEdit = props => (
    <Edit title={<DeviceTypeTitle />} {...props}>
         <TabbedForm>
            <FormTab label="Edition de l'objet">
                <TextInput disabled source="id" />
                <TextInput source="model" validate={required()} />
                <TextInput source="slug" />
                <TextInput source="comment" label="Description" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default DeviceTypeEdit;
