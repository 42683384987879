import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    BooleanField,
    TextField,
    UrlField
} from 'react-admin';
import ClientVRFTitle from './ClientVRFTitle';

const ClientVRFShow = () => (
     <Show title={<ClientVRFTitle />}>
        <TabbedShowLayout>
            <Tab label="clientvrf.form.summary">
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="rm" />
                <BooleanField source={"enabled"} />
            </Tab>
            <Tab label="clientvrf.form.miscellaneous">
                <TextField source="comment" />
                <UrlField source="url" target="_blank"/>
            </Tab>
            <Tab label="clientvrf.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ClientVRFShow;
