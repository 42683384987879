import React, { useEffect, useState } from "react";
import { useGetList } from 'react-admin';
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  Grid,
} from "@mui/material";

import { makeStyles } from '@mui/styles';

import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1)
  },
  progressSection: {
    marginBottom: theme.spacing(1)
  },
  progressTitle: {
    marginBottom: theme.spacing(2)
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  pieChartLegendWrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: theme.spacing(1)
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  tableWidget: {
    overflowX: "auto"
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing(1)
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1)
  },
  serverOverviewElement: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%"
  },
  serverOverviewElementText: {
    minWidth: 145,
    paddingRight: theme.spacing(2)
  },
  serverOverviewElementChartWrapper: {
    width: "100%"
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: 'wrap',
    }
  },
  mainChartHeaderLabels: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("xs")]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    }
  },
  mainChartHeaderLabel: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + '80 !important',
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25
  },
  mainChartLegentElement: {
    fontSize: '18px !important',
    marginLeft: theme.spacing(1),
  }
}));

const ActionList = ({ data }) => (
    <List>
    {data.map(el =>
      <React.Fragment key={el.id}>
        <ListItem alignItems="flex-start" key={el.id}>
          <ListItemText
            primary={el.action + " " + el.model+" named "+el.repr}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                >
                  {el.time}
                </Typography>
                {" — by "+el.user}
              </React.Fragment>
            }
          />
        </ListItem>
        <Divider variant="inset" component="li" />
      </React.Fragment>
    )}
    </List>
);
ActionList.defaultProps = {
    data: [],
};

const Dashboard = () => {
  const [nbL2tp, setNbL2tp] = useState(0);
  const [nbCircuit, setNbCircuits] = useState(0);
  const [nbCollectL2, setNbCollectsL2] = useState(0);
  const [nbCollectL2TP, setNbCollectsL2TP] = useState(0);
  const [actions, setActions] = useState([]);
  const styles = useStyles();

  const { data: l2tps, total: totalL2tp } = useGetList('l2tp', {
     pagination: { page: 1, perPage: 100 },
     sort: { field: 'id', order: 'DESC' },
  });
  useEffect(()=> {
    if (l2tps) {
      setNbL2tp(totalL2tp)
    }
  }, [l2tps, totalL2tp]);

  const { data: circuits, total: totalCircuits } = useGetList('circuits', {
     pagination: { page: 1, perPage: 100 },
     sort: { field: 'id', order: 'DESC' },
  });
  useEffect(()=> {
    if (circuits) {setNbCircuits(totalCircuits)}
  }, [circuits, totalCircuits]);

  const { data: collectsl2, total: totalCollectsL2 } = useGetList('collectsl2', {
     pagination: { page: 1, perPage: 100 },
     sort: { field: 'id', order: 'DESC' },
  });
  useEffect(()=> {
    if (collectsl2) { setNbCollectsL2(totalCollectsL2)}
  }, [collectsl2, totalCollectsL2]);

  const { data: collectsl2tp, total: totalCollectsL2TP } = useGetList('collectsl2tp', {
     pagination: { page: 1, perPage: 100 },
     sort: { field: 'id', order: 'DESC' },
  });
  useEffect(()=> {
    if (collectsl2tp) { setNbCollectsL2TP(totalCollectsL2TP)}
  }, [collectsl2tp, totalCollectsL2TP]);

  const { data: listActions } = useGetList('actions', {
     pagination: { page: 1, perPage: 100 },
     sort: { field: 'id', order: 'DESC' },
  });
  useEffect(()=> {
    if (listActions) { setActions(listActions)}
  }, [listActions]);


   return (
      <React.Fragment>
        <PageTitle title="Dashboard" />
        <Grid container spacing={3}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Widget
              title="L2TP"
              upperTitle
              bodyClass={styles.fullHeightBody}
              className={styles.card}
              disableWidgetMenu
            >
              <div className={styles.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  { nbL2tp }
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Widget
              title="Circuits"
              upperTitle
              bodyClass={styles.fullHeightBody}
              className={styles.card}
              disableWidgetMenu
            >
              <div className={styles.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  { nbCircuit }
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Widget
              title="Collecte L2"
              upperTitle
              bodyClass={styles.fullHeightBody}
              className={styles.card}
              disableWidgetMenu
            >
              <div className={styles.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  { nbCollectL2 }
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <Widget
              title="Collecte L2TP"
              upperTitle
              bodyClass={styles.fullHeightBody}
              className={styles.card}
              disableWidgetMenu
            >
              <div className={styles.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  { nbCollectL2TP }
                </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              title="Actions Log"
              upperTitle
              noBodyPadding
              bodyClass={styles.tableWidget}
              disableWidgetMenu
            >
               <ActionList data={actions} />
            </Widget>
          </Grid>
        </Grid>
      </React.Fragment>
    );
};

export default Dashboard;