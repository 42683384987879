import React, {useState} from "react";
import classnames from "classnames";
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { MoreVert as MoreIcon } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  widgetWrapper: {
    display: "flex",
    minHeight: "100%"
  },
  widgetHeader: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  widgetRoot: {
    boxShadow: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  widgetBody: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  noPadding: {
    padding: 0
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden"
  },
  moreButton: {
    margin: -theme.spacing(1),
    padding: 0,
    width: 40,
    height: 40,
    color: theme.palette.text.hint,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "rgba(255, 255, 255, 0.35)"
    }
  }
}));

const Widget = ({
  children,
  title,
  noBodyPadding,
  bodyClass,
  className,
  disableWidgetMenu,
  ...props
}) => {
  const classes = useStyles();
  const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
  const moreButtonRef = null;

  return (
      <div className={classes.widgetWrapper}>
        <Paper className={classes.paper} classes={{ root: classes.widgetRoot }}>
          <div className={classes.widgetHeader}>
            {props.header ? (
                props.header
            ) : (
                <React.Fragment>
                  <Typography variant="subtitle1" color="textSecondary">
                    {title}
                  </Typography>
                  {!disableWidgetMenu && (
                      <IconButton
                          color="primary"
                          classes={{ root: classes.moreButton }}
                          aria-owns="widget-menu"
                          aria-haspopup="true"
                          onClick={() => setMoreMenuOpen(true)}
                      >
                        <MoreIcon />
                      </IconButton>
                  )}
                </React.Fragment>
            )}
          </div>
          <div
              className={classnames(classes.widgetBody, {
                [classes.noPadding]: noBodyPadding,
                [bodyClass]: bodyClass
              })}
          >
            {children}
          </div>
        </Paper>
        <Menu
            id="widget-menu"
            open={isMoreMenuOpen}
            anchorEl={moreButtonRef}
            onClose={() => setMoreMenuOpen(false)}
            disableAutoFocusItem
        >
          <MenuItem>
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem>
            <Typography>Copy</Typography>
          </MenuItem>
          <MenuItem>
            <Typography>Delete</Typography>
          </MenuItem>
          <MenuItem>
            <Typography>Print</Typography>
          </MenuItem>
        </Menu>
      </div>
  );
};



export default Widget;
