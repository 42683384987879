import CallSplitIcon from '@mui/icons-material/CallSplit';
import IPRealmCreate from './IPRealmCreate';
import IPRealmEdit from './IPRealmEdit';
import IPRealmList from './IPRealmList';
import IPRealmShow from './IPRealmShow';

export default {
    list: IPRealmList,
    create: IPRealmCreate,
    edit: IPRealmEdit,
    show: IPRealmShow,
    icon: CallSplitIcon,
};
