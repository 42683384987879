import React, {Children, cloneElement, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    AppBar as MuiAppBar,
    Toolbar,
    Typography,
    MenuItem
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {MenuItemLink, SidebarToggleButton, Logout, useGetIdentity, useDataProvider} from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserMenu, LoadingIndicator } from 'ra-ui-materialui';
import useWebSocket from "react-use-websocket";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
    toolbar: {
        paddingRight: 24,
    },
    menuButton: {
        marginLeft: '0.5em',
        marginRight: '0.5em',
    },
    menuButtonIconClosed: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
        transition: theme.transitions.create(['transform'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        transform: 'rotate(180deg)',
    },
    title: {
        flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }
}));

const ProvisionUserMenu = (props) => {
    const { identity } = useGetIdentity();
    let userTitle = "";
    if(identity) {
        userTitle = identity.fullName
    }

   return <UserMenu {...props}>
    <MenuItem>
      {userTitle}
    </MenuItem>
    <MenuItemLink
        to="/my-profile"
        primaryText="Profile"
        leftIcon={<SettingsIcon />}
    />
       <Logout />
       <MenuItem>v1.{process.env.REACT_APP_VERSION}</MenuItem>
   </UserMenu>;
};

const AppBar = ({
    children,
    className,
    logo,
    logout,
    open,
    title,
    userMenu,
    width,
    ...rest
}) => {
    const classes = useStyles();
    const sidebarToggleButtonClasses = {
        menuButtonIconClosed: classes.menuButtonIconClosed,
        menuButtonIconOpen: classes.menuButtonIconOpen,
    };
    const dataProvider = useDataProvider();

    const getSocketUrl = useCallback(() => {
      return new Promise((resolve) => {
        dataProvider.websocketUrl().then((url) => {
            resolve(url);
        })
      });
    }, []);

    const {lastMessage} = useWebSocket(getSocketUrl);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (lastMessage){
            const payload = JSON.parse(lastMessage.data);
            if (payload && payload.type === 'notify'){
                enqueueSnackbar(payload.message, {variant: payload.status});
            }
        }
      }, [lastMessage]);

    return (<MuiAppBar
                className={className}
                color="primary"
                {...rest}
            >
                <Toolbar
                    disableGutters
                    variant={width === 'xs' ? 'regular' : 'dense'}
                    className={classes.toolbar}
                >
                    <SidebarToggleButton
                        className={classes.menuButton}
                        classes={sidebarToggleButtonClasses}
                    />

                    {Children.count(children) === 0 ? (
                        <Typography
                            variant="h6"
                            color="inherit"
                            className={classes.title}
                            id="react-admin-title"
                        />
                    ) : (
                        children
                    )}
                    <LoadingIndicator/>
                    {cloneElement(userMenu, {logout})}
                </Toolbar>
            </MuiAppBar>
    );
};

AppBar.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    logout: PropTypes.element,
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
    userMenu: PropTypes.node,
    width: PropTypes.string,
};

AppBar.defaultProps = {
    userMenu: <ProvisionUserMenu />,
};

export default AppBar;
