import React, {useState, useRef, useEffect} from "react";

import {Admin, Resource, CustomRoutes, defaultTheme} from "react-admin";
import Keycloak from 'keycloak-js';

import {Route} from 'react-router-dom';
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/styles';
import {CssBaseline} from '@mui/material';

import {keycloakAuthProvider, httpClient} from './components/Provider/Auth';
import drfProvider from './components/Provider/Data/drfProvider';
import i18nProvider from "./components/Provider/i18n/i18nProvider";
import dashboard from './pages/dashboard';
import l2tp from './pages/l2tp';
import circuits from './pages/circuits';
import collectsl2 from './pages/collectsl2';
import collectsl2tp from './pages/collectsl2tp';
import typescircuit from './pages/typescircuit';
import realms from './pages/realms';
import Login from './pages/login';
import users from './pages/users';
import radiusrealm from './pages/radiusrealm';
import iprealms from './pages/iprealm';
import router from './pages/router';
import cpe from './pages/cpe';
import transitgw from './pages/transitgw';
import configs from './pages/configs';
import clientvrfs from './pages/clientvrfs';
import devicetypes from './pages/devicetypes';
import ProfileEdit from './pages/profile';
import * as Constants from './constants';
import themes from './themes';
import ProvisionLayout from './components/Layout/ProvisionLayout';


const config = {
    url: Constants.KEYCLOAK_URL,
    realm: Constants.KEYCLOAK_REALM,
    clientId: Constants.KEYCLOAK_CLIENT_ID,
};

const initOptions = {
    onLoad: 'login-required',
    flow: 'implicit',
};

const getPermissions = (decoded) => {
    const roles = decoded?.groups;
    if (!roles) {
        return false;
    }
    if (roles.includes('/netadmins')) return 'admin';
    if (roles.includes('/deploy')) return 'deploy';
    if (roles.includes('/support')) return 'support';
    if (roles.includes('/ipausers')) return 'viewer';
    return false;
};

const raKeycloakOptions = {
    onPermissions: getPermissions,
};

const theme = createTheme(defaultTheme, themes.default);

const App = () => {
    const [keycloak, setKeycloak] = useState(undefined);
    const authProvider = useRef(undefined);
    const dataProvider = useRef(undefined);

    const initKeyCloakClient = async () => {
        const keycloakClient = new Keycloak(config);
        await keycloakClient.init(initOptions);
        authProvider.current = keycloakAuthProvider(
            keycloakClient,
            raKeycloakOptions
        );
        dataProvider.current = drfProvider(Constants.APIURL, httpClient(keycloakClient));
        setKeycloak(keycloakClient);
    };

    useEffect(() => {
        if (!keycloak) initKeyCloakClient();
    }, [keycloak]);

    if (!keycloak) return <ThemeProvider theme={theme}><Login keycloakClient={initKeyCloakClient}/></ThemeProvider>;

    return (
        <>
            <CssBaseline/>
            <Admin
                authProvider={authProvider.current}
                dataProvider={dataProvider.current}
                i18nProvider={i18nProvider}
                theme={theme}
                dashboard={dashboard}
                title="Leonix Provision"
                layout={ProvisionLayout}
            >
                <CustomRoutes>
                    <Route key="my-profile" path="/my-profile" element={<ProfileEdit/>}/>
                </CustomRoutes>
                {permissions => [
                    <Resource name="l2tp"
                              list={l2tp.list}
                              edit={['admin', 'deploy'].includes(permissions) ? l2tp.edit : null}
                              create={['admin', 'deploy'].includes(permissions) ? l2tp.create : null}
                              show={l2tp.show}
                              icon={l2tp.icon} options={{label: 'L2TP'}}/>,
                    <Resource name="circuits"
                              list={circuits.list}
                              edit={['admin', 'deploy'].includes(permissions) ? circuits.edit : null}
                              create={['admin', 'deploy'].includes(permissions) ? circuits.create : null}
                              show={circuits.show}
                              icon={circuits.icon} options={{label: 'Circuits'}}/>,
                    <Resource name="collectsl2"
                              list={collectsl2.list}
                              edit={permissions === 'admin' ? collectsl2.edit : null}
                              create={permissions === 'admin' ? collectsl2.create : null}
                              show={collectsl2.show}
                              icon={collectsl2.icon} options={{label: 'Collectes L2', title: 'Collectes L2'}}/>,
                    <Resource name="collectsl2tp"
                              list={collectsl2tp.list}
                              edit={permissions === 'admin' ? collectsl2tp.edit : null}
                              create={permissions === 'admin' ? collectsl2tp.create : null}
                              show={collectsl2tp.show}
                              icon={collectsl2tp.icon} options={{label: 'Collectes L2TP', title: 'Collectes L2TP'}}/>,
                    <Resource name="typescircuit"
                              list={typescircuit.list}
                              edit={permissions === 'admin' ? typescircuit.edit : null}
                              create={permissions === 'admin' ? typescircuit.create : null}
                              show={typescircuit.show}
                              icon={typescircuit.icon}
                              options={{label: 'Types Circuit'}}/>,
                    <Resource name="clientvrfs"
                              list={clientvrfs.list}
                              edit={permissions === 'admin' ? clientvrfs.edit : null}
                              create={permissions === 'admin' ? clientvrfs.create : null}
                              show={clientvrfs.show}
                              icon={clientvrfs.icon}
                              options={{label: 'VRF Clients'}}/>,
                    <Resource name="ip_realm"
                              list={iprealms.list}
                              edit={permissions === 'admin' ? iprealms.edit : null}
                              create={permissions === 'admin' ? iprealms.create : null}
                              show={iprealms.show}
                              icon={iprealms.icon} options={{label: 'IP for Realm'}}/>,
                    <Resource name="realms"
                              list={realms.list}
                              edit={permissions === 'admin' ? realms.edit : null}
                              create={permissions === 'admin' ? realms.create : null}
                              show={realms.show}
                              icon={realms.icon} options={{label: 'Realms L2TP'}}/>,
                    <Resource name="radiusrealm"
                              list={radiusrealm.list}
                              edit={permissions === 'admin' ? radiusrealm.edit : null}
                              create={permissions === 'admin' ? radiusrealm.create : null}
                              show={radiusrealm.show}
                              icon={radiusrealm.icon} options={{label: 'Radius for Realm'}}/>,
                    <Resource name="routers"
                              list={router.list}
                              edit={permissions === 'admin' ? router.edit : null}
                              create={permissions === 'admin' ? router.create : null}
                              show={router.show}
                              icon={router.icon} options={{label: 'Router', title: 'Router'}}/>,
                    <Resource name="transitgw"
                              list={transitgw.list}
                              edit={['admin'].includes(permissions) ? transitgw.edit : null}
                              create={['admin'].includes(permissions) ? transitgw.create : null}
                              show={transitgw.show}
                              icon={transitgw.icon} options={{label: 'Transit & Gateway'}}/>,
                    <Resource name="cpe"
                              list={cpe.list}
                              edit={permissions === 'admin' ? cpe.edit : null}
                              create={permissions === 'admin' ? cpe.create : null}
                              show={cpe.show}
                              icon={cpe.icon} options={{label: 'CPE', title: 'CPE'}}/>,
                    <Resource name="devicetypes"
                              list={devicetypes.list}
                              edit={permissions === 'admin' ? devicetypes.edit : null}
                              create={permissions === 'admin' ? devicetypes.create : null}
                              show={devicetypes.show}
                              icon={devicetypes.icon} options={{label: 'DEVICETYPE', title: 'DEVICETYPE'}}/>,
                    <Resource name="configs"
                              list={configs.list}
                              edit={permissions === 'admin' ? configs.edit : null}
                              create={permissions === 'admin' ? configs.create : null}
                              show={configs.show}
                              icon={configs.icon}
                              options={{label: 'Configs'}}/>,
                    <Resource name="groups"/>,
                    <Resource name="sites"/>,
                    <Resource name="actions"/>,
                    <Resource name="companies"/>,
                    <Resource name="users"
                              list={users.list}
                              edit={users.edit}
                              create={permissions === 'admin' ? users.create : null}
                              show={users.show}
                              icon={users.icon}/>
                ]}
            </Admin>
        </>

    );
};
export default App;