import React from 'react';
import {
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    TextField,
    BooleanField
} from 'react-admin';
import RadiusRealmTitle from './RadiusRealmTitle';

const RadiusRealmShow = () => (
    <Show title={<RadiusRealmTitle />} >
        <TabbedShowLayout>
            <Tab label="radiusrealm.form.summary">
                <TextField source="id" />
                <TextField source="addr" />
                <TextField source="auth_port" />
                <TextField source="acct_port" />
                <BooleanField source="enabled" />
            </Tab>
            <Tab label="radiusrealm.form.security">
                <TextField source="secret" />
            </Tab>
            <Tab label="radiusrealm.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default RadiusRealmShow;
