import React from 'react';
import {
    Create,
    AutocompleteInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required, useNotify, useRedirect
} from 'react-admin';
import CollectL2TPTitle from './CollectL2TPTitle';
import {useFormContext} from "react-hook-form";

const CollectL2TPCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/collectsl2tp', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const CollectL2TPCreate = props => (
    <Create title={<CollectL2TPTitle />} {...props}>
        <SimpleForm
            toolbar={<CollectL2TPCreateToolbar />}
        >
            <TextInput source="name" validate={required()} />
            <ReferenceInput label="Site" source="site" reference="sites" sort={{field: 'name', order: 'ASC'}}>
                <AutocompleteInput optionText={"name"}/>
            </ReferenceInput>
            <TextInput multiline source="comment" />

        </SimpleForm>
    </Create>
);

export default CollectL2TPCreate;
