import React from 'react';
import {
    Edit,
    FormTab,
    ReferenceInput,
    AutocompleteInput,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin';
import CollectL2Title from './CollectL2Title';

const CollectL2Edit = props => (
    <Edit title={<CollectL2Title />} {...props}>
         <TabbedForm>
            <FormTab label="collectl2tp.form.summary">
                <TextInput source="id" disabled/>
                <TextInput source="name" validate={required()} />
                <ReferenceInput label="Site" source="site" reference="sites" sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText={"name"}/>
                </ReferenceInput>
                <ReferenceInput label="Router" source="router" reference="routers" sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText={"name"}/>
                </ReferenceInput>
                <TextInput source="interface" />
            </FormTab>
            <FormTab label="collectl2tp.form.miscellaneous">
                <TextInput multiline source="comment" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default CollectL2Edit;
