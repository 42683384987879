import React from 'react';
import { useTranslate } from 'react-admin';

export default ({ record }) => {
    const translate = useTranslate();
    return (
        <span>
            {record ? translate('user.edit.title', { title: record.email }) : translate('user.show.title')}
        </span>
    )
};