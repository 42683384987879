import BookIcon from '@mui/icons-material/Book';
import SearchIcon from '@mui/icons-material/Search';
import MemoryIcon from '@mui/icons-material/Memory';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import {
    useNotify,
    BulkDeleteButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ShowButton,
    SimpleList,
    ReferenceField,
    TextField,
    TextInput,
    Button,
    fetchUtils,
    UrlField,
    usePermissions
} from 'react-admin';
import ConfigsTitle from './ConfigsTitle';
import * as Constants from "../../constants";
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";

export const PostIcon = BookIcon;

const ConfigsFilter = props => (
    <Filter {...props}>
        <TextInput
            label="configs.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const GenerateConfButton = props => {
    const notify = useNotify();
    return  <Button label={"Generate"} onClick={e => {
                e.stopPropagation();
                let apiUrl = Constants.APIURL;
                let options = {method: 'POST'};
                let resource = props.resource;
                let id = props.record.id;
                let url = `${apiUrl}/${resource}/${id}/generation/`;
                fetchUtils.fetchJson(url, options).then(response => {
                    const {json} = response;
                    console.log(json);
                    notify('Generation launched');
                }).catch((e) => {
                    notify('Error: generation not launched', 'warning')
                });
            }}>
                <MemoryIcon/>
            </Button>
};

const ConfigsListBulkActions = props => (
    <Fragment>
        <BulkDeleteButton {...props}/>
    </Fragment>
);

const ConfigsList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {permissions} = usePermissions();
    const styles = useStyles();

    return (
        <List
        {...props}
        title={<ConfigsTitle />}
        bulkActionButtons={<ConfigsListBulkActions />}
        filters={<ConfigsFilter />}
        perPage={25}
        sort={{ field: 'id', order: 'ASC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.company}
                    secondaryText={record => `${record.leoid}`}
                    tertiaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ):(
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" cellClassName={styles.name} />
                    <ReferenceField label="Circuit" source="circuit" reference="circuits">
                        <TextField source="leoid" />
                    </ReferenceField>
                    <TextField source="conf" />
                    <TextField source="type_cpe" />
                    <UrlField source="awx_jobs" target="_blank"/>
                    <ListActionsToolbar>
                        {permissions === 'Admin' ? <EditButton /> : <Fragment />}
                        <ShowButton />
                        <GenerateConfButton/>
                     </ListActionsToolbar>
                </Datagrid>
            )}
    </List>
    )
};

export default ConfigsList;
