import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import {
    BooleanField,
    BulkDeleteButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ShowButton,
    SimpleList,
    TextField,
    TextInput,
    usePermissions
} from 'react-admin';
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";

const RouterFilter = props => (
    <Filter {...props}>
        <TextInput
            label="router.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const RouterListBulkActions = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const RouterList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const styles = useStyles();
    const {permissions} = usePermissions();
    return (
        <List
        {...props}
        bulkActionButtons={<RouterListBulkActions />}
        filters={<RouterFilter />}
        sort={{ field: 'id', order: 'DESC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    tertiaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ):(
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" cellClassName={styles.company} />
                    <TextField source="ipv4_address" label="IP Loopback 0"/>
                    <BooleanField
                        source="enabled"
                        label="enabled"
                        sortable={false}
                    />
                    <TextField source="netbox_id" />
                    <ListActionsToolbar>
                        {permissions === 'admin' ? <EditButton /> : <Fragment/> }
                        <ShowButton />
                    </ListActionsToolbar>
                </Datagrid>
            )}
    </List>
    )
};

export default RouterList;
