import React from 'react';
import {
    Create,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required, useNotify, useRedirect
} from 'react-admin';
import TypeCircuitTitle from './TypesCircuitTitle';
import {useFormContext} from "react-hook-form";

const TypeCircuitCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/typescircuit', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const TypesCircuitCreate = props => (
    <Create title={<TypeCircuitTitle />} {...props}>
        <SimpleForm
            toolbar={<TypeCircuitCreateToolbar />}
        >
            <TextInput source="name" validate={required()} />
            <TextInput source="netbox_id" validate={required()} />
            <TextInput source="base_leoid" validate={required()} />

        </SimpleForm>
    </Create>
);

export default TypesCircuitCreate;
