import CallSplitIcon from '@mui/icons-material/CallSplit';
import ConfigsCreate from './ConfigsCreate';
import ConfigsEdit from './ConfigsEdit';
import ConfigsList from './ConfigsList';
import ConfigsShow from './ConfigsShow';

export default {
    list: ConfigsList,
    create: ConfigsCreate,
    edit: ConfigsEdit,
    show: ConfigsShow,
    icon: CallSplitIcon,
};
