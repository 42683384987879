import React from 'react';
import { useTranslate, useRecordContext } from 'react-admin';

export default () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return (
        <span>
            {record ? translate('collectl2.edit.title', { title: record.name }) : translate('collectl2.show.title')}
        </span>
    )
};