import CallSplitIcon from '@mui/icons-material/CallSplit';
import ClientVRFCreate from './ClientVRFCreate';
import ClientVRFEdit from './ClientVRFEdit';
import ClientVRFList from './ClientVRFList';
import ClientVRFShow from './ClientVRFShow';

export default {
    list: ClientVRFList,
    create: ClientVRFCreate,
    edit: ClientVRFEdit,
    show: ClientVRFShow,
    icon: CallSplitIcon,
};
