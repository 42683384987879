import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    required,
} from 'react-admin';
import ClientVRFTitle from './ClientVRFTitle';

const ClientVRFEdit = props => (
    <Edit title={<ClientVRFTitle />} {...props}>
         <TabbedForm>
            <FormTab label="clientvrf.form.summary">
                <TextInput disabled source="id" />
                <TextInput source="name" validate={required()} />
                <TextInput source="comment" />
                <TextInput source="rm" />
                <BooleanInput source="enabled" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default ClientVRFEdit;
