import React, {useEffect, useState} from 'react';
import {
    BooleanInput,
    Edit,
    EditBase,
    FormTab,
    AutocompleteInput,
    ReferenceInput,
    NumberInput,
    TabbedForm,
    TextInput,
    number,
    required, SelectInput, useRecordContext,
} from 'react-admin';
import TransitGWTitle from './TransitGWTitle';

const TransitGWEditBase = props => {
    const record = useRecordContext();
    const [showTransitTab, setShowTransitTab] = useState(false);

    useEffect(()=>{
        console.log(record);
        setShowTransitTab(record.circuit_type === 'Transit');
    }, [record]);

    return <EditBase {...props}>
        <TabbedForm>
            <FormTab label="transitgw.form.summary">
                <TextInput disabled source="id" />
                <ReferenceInput label="Company" source="company" reference="companies" sort={{field: 'nom', order: 'ASC'}}>
                    <AutocompleteInput optionText="nom"/>
                </ReferenceInput>
                <TextInput disabled source="leoid" />
                <BooleanInput source="enabled" defaultValue />
            </FormTab>
            <FormTab label="transitgw.form.network">
                <TextInput source="ipv4_address" validate={required()} />
                <TextInput source="ipv6_address"/>
                <SelectInput source={"commit"} choices={[
                        {id: 100000, name: '100M'},
                        {id: 200000, name: '200M'},
                        {id: 300000, name: '300M'},
                        {id: 500000, name: '500M'},
                        {id: 700000, name: '700M'},
                        {id: 1000000, name: '1G'},
                        {id: 2000000, name: '2G'},
                        {id: 3000000, name: '3G'},
                        {id: 5000000, name: '5G'},
                        {id: 7000000, name: '7G'},
                        {id: 10000000, name: '10G'}
                    ]}/>
                    <SelectInput source={"port"} choices={[
                        {id: 10000000, name: '10G'},
                        {id: 1000000, name: '1G'},
                    ]}/>
                {!showTransitTab && <TextInput source="ipv6_prefix"/>}
            </FormTab>
            {showTransitTab && <FormTab label="transitgw.form.transit">
                <NumberInput source="asn" label="ASN"/>
                <BooleanInput source="originate" defaultValue={false} defaultChecked={false}/>
                <TextInput source="import_policy"/>
                <TextInput source="export_policy"/>
                <NumberInput source="peering_manager_connid"/>
                <BooleanInput source="bfd" defaultValue={false} defaultChecked={false}/>
                <TextInput source="password"/>
            </FormTab>}
            <FormTab label="transitgw.form.miscellaneous">
                <TextInput multiline source="comment" />
            </FormTab>
        </TabbedForm>
    </EditBase>
};

const TransitGWEdit = props => (
    <Edit title={<TransitGWTitle/>} {...props}>
            <TransitGWEditBase {...props} />
        </Edit>
);

export default TransitGWEdit;
