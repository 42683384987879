import BookIcon from '@mui/icons-material/Book';
import CollectL2Create from './CollectL2Create';
import CollectL2Edit from './CollectL2Edit';
import CollectL2List from './CollectL2List';
import CollectL2Show from './CollectL2Show';

export default {
    list: CollectL2List,
    create: CollectL2Create,
    edit: CollectL2Edit,
    show: CollectL2Show,
    icon: BookIcon,
};
