import React from 'react';
import {
    Edit,
    FormTab,
    ReferenceInput,
    AutocompleteInput,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin';
import CollectL2TPTitle from './CollectL2TPTitle';

const CollectL2TPEdit = props => (
    <Edit title={<CollectL2TPTitle />} {...props}>
         <TabbedForm>
            <FormTab label="collectl2tp.form.summary">
                <TextInput disabled source="id" />
                <TextInput source="name" validate={required()} />
                <ReferenceInput label="Site" source="site" reference="sites" sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText={"name"}/>
                </ReferenceInput>
            </FormTab>
            <FormTab label="collectl2tp.form.miscellaneous">
                <TextInput multiline source="comment" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default CollectL2TPEdit;
