import ReactDOM from "react-dom";
import { SnackbarProvider } from 'notistack';

import App from "./app";

const container = document.getElementById("root");

ReactDOM.render(
    <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top',horizontal: 'right'}}>
        <App/>
    </SnackbarProvider>
,container);
