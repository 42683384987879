/* eslint react/jsx-key: off */
import React from 'react';
import { Show, Tab, TabbedShowLayout, TextField, BooleanField } from 'react-admin';
import UserTitle from './UserTitle';

const UserShow = ({ permissions, ...props }) => (
    <Show title={<UserTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="user.form.summary">
                <TextField source="id" />
                <TextField source="email" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <BooleanField source="is_superuser" />
                <BooleanField source="is_active" />
            </Tab>
            {permissions === 'Admin' && (
                <Tab label="user.form.security">
                    <TextField source="role" />
                </Tab>
            )}
        </TabbedShowLayout>
    </Show>
);

export default UserShow;
