import frenchMessages from 'ra-language-french';

export default {
    simple: {
        action: {
            resetViews: 'Réinitialiser des vues',
        },
    },
    ...frenchMessages,
    resources: {
        l2tp: {
            name: 'L2TP |||| L2TP',
            fields: {
                company: 'Etablissement',
                comment: 'Commentaire',
                enable: 'Activé',
                leoid: 'LEOID',
                ipv4_address: 'Adresse IPv4',
                ipv6_address: 'Adresse IPv6',
                ipv6_prefix: 'Préfixe IPv6',
                bw_down: 'Débit Descendant',
                bw_up: 'Débit Montant',
                created: 'Créé le',
                modified: 'Modifié le',
                created_by: 'Crée par',
                modified_by: 'Modifié par',
            },
        },
        users: {
            name: 'Utilisateur |||| Utilisateurs',
            fields: {
                firstname: 'Prénom',
                lastname: 'Nom',
                email: 'Mél',
                role: 'Role',
            },
        },
    },
    router: {
        list: {
            search: 'Recherche',
        },
        form: {
            summary: 'Sommaire',
            body: 'Corps',
            variables: 'Variables',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Commentaires'
        },
        edit: {
            title: 'Routeur %{title}',
        },
        show: {
            title: 'Routeur',
        },
        action: {
            save_and_add: 'Sauvegarder et ajouter',
            save_and_show: 'Sauvegarder et voir',
        },
    },
    l2tp: {
        list: {
            search: 'Recherche',
        },
        form: {
            summary: 'Résumé',
            body: 'Contenu',
            miscellaneous: 'Extra',
            comments: 'Commentaires',
        },
        edit: {
            title: 'L2TP "%{title}"',
        },
    },
    user: {
        list: {
            search: 'Recherche',
        },
        form: {
            summary: 'Résumé',
            security: 'Sécurité',
        },
        edit: {
            title: 'Utilisateur "%{title}"',
        },
    },
};
