import React from 'react';
import {
    BooleanInput,
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
} from 'react-admin';
import RouterTitle from './RouterTitle';

const RouterEdit = props => (
    <Edit title={<RouterTitle />} {...props}>
        <TabbedForm defaultValue={{ average_note: 0 }}>
            <FormTab label="router.form.summary">
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="ipv4_address" label="IP Loopback 0"/>
                <BooleanInput source="enabled" defaultValue />
                <TextInput source="netbox_id" />
            </FormTab>
            <FormTab label="router.form.miscellaneous">
                <TextInput multiline source="comment" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default RouterEdit;
