import React from 'react';
import {useRecordContext, useTranslate} from 'react-admin';

export default () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return (
        <span>
            {record ? translate('realm.edit.title', { title: record.domain }) : translate('realm.show.title') }
        </span>
    )
};