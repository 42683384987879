import React from "react";
import ReactDOM from 'react-dom';

const PageTitle = props => {
      const element = props.title;
      const target = document.getElementById('react-admin-title');
      if(target){
          return ReactDOM.createPortal(element, document.getElementById('react-admin-title'))
      }else{
          return <div />;
      }
}
export default PageTitle;