import React from 'react';
import {
    Create,
    AutocompleteInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required, useNotify, useRedirect
} from 'react-admin';
import CollectL2Title from './CollectL2Title';
import {useFormContext} from "react-hook-form";

const CollectL2CreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/collectsl2', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const CollectL2Create = props => (
    <Create title={<CollectL2Title />} {...props}>
        <SimpleForm
            toolbar={<CollectL2CreateToolbar />}
            validate={values => {
                const errors = {};
                ['site'].forEach(field => {
                    if (!values[field]) {
                        errors[field] = ['Required field'];
                    }
                });

                return errors;
            }}
        >
            <TextInput source="name" validate={required()} />
            <ReferenceInput label="Site" source="site" reference="sites" sort={{field: 'name', order: 'ASC'}}>
                <AutocompleteInput optionText={"name"}/>
            </ReferenceInput>
            <ReferenceInput label="Router" source="router" reference="routers" sort={{field: 'name', order: 'ASC'}}>
                <AutocompleteInput optionText={"name"}/>
            </ReferenceInput>
            <TextInput source="interface" />
            <TextInput multiline source="comment" />

        </SimpleForm>
    </Create>
);

export default CollectL2Create;
