import React, {Fragment, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';
import {
    useNotify,
    ShowBase,
    Show,
    Tab,
    TabbedShowLayout,
    ReferenceField,
    DateField,
    TextField,
    BooleanField,
    Labeled,
    EditButton,
    Button,
    useRecordContext,
    TopToolbar, useDataProvider,
    SelectInput, Form
} from 'react-admin';
import CpeTitle from './CpeTitle';
import {Typography} from "../../components/Wrappers";
import SyncIcon from '@mui/icons-material/Sync';
import SettingsPowerIcon from '@mui/icons-material/SettingsPower';
import GetAppIcon from '@mui/icons-material/GetApp';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CachedIcon from '@mui/icons-material/Cached';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Chip,
    Tooltip, Dialog, DialogTitle, DialogContent, RadioGroup, FormControlLabel, Radio, DialogActions
} from '@mui/material';

const intervalToLevels = (interval, levels) => {
  const cbFun = (d, c) => {
    let bb = d[1] % c[0],
      aa = (d[1] - bb) / c[0];
    aa = aa > 0 ? aa + c[1] : '';

    return [d[0] + aa, bb];
  };

  let rslt = levels.scale.map((d, i, a) => a.slice(i).reduce((d, c) => d * c))
    .map((d, i) => ([d, levels.units[i]]))
    .reduce(cbFun, ['', interval]);
  return rslt[0];
};

const TimeLevels = {
  scale: [24, 60, 60, 1],
  units: ['d ', 'h ', 'm ', 's ']
};
const secondsToString = interval => intervalToLevels(interval._value, TimeLevels);

const periodToBandwdith = (period) => {
    let sec = period / 1000000;
    let payload = 100*1024*1024;
    let bandwidth = payload / sec;
    let e = Math.floor(Math.log(bandwidth) / Math.log(1024));
    return ((bandwidth/Math.pow(1024, e))*8).toFixed(2)+' '+' KMGTP'.charAt(e)+'bits/s';
}

const toBandwidth = period => periodToBandwdith(period._value);

const existAcsKey = (acs, path, cur_path='Device') => {
    const array_path = path.split('.');
    const array_cur_path = cur_path.split('.');
    const last_item = array_cur_path[array_cur_path.length - 1];
    if (last_item in acs) {
        if(cur_path === path) {
            return true;
        } else {
            return existAcsKey(acs[last_item], path, array_path.slice(0, array_cur_path.length+1).join('.'));
        }
    } else {
        return false;
    }
};

const getAcsValue = (acs, path, cur_path='Device') => {
    const array_path = path.split('.');
    const array_cur_path = cur_path.split('.');
    const last_item = array_cur_path[array_cur_path.length - 1];
    if (last_item in acs) {
        if(cur_path === path) {
            return acs[last_item];
        } else {
            return getAcsValue(acs[last_item], path, array_path.slice(0, array_cur_path.length+1).join('.'));
        }
    } else {
        return '';
    }
};

const ACSFieldValue = ({value}) => {
    if(value.hasOwnProperty('_value')) {
        return typeof value['_value'] == 'string' ? value['_value'] : JSON.stringify(value['_value']);
    } else {
        return typeof value == 'string' ? value : JSON.stringify(value);
    }
}

const ACSField = ({ className, value, label = null  }) => {
    if(label) {
        return (<Labeled label={label}>
            <Typography
                component="span"
                variant="body1"
                className={className}
            >
                {value ? (<ACSFieldValue value={value} />) : ''}
            </Typography>
        </Labeled>
        )
    } else {
        return (<Typography
            component="span"
            variant="body1"
            className={className}
        >
            {value ? (<ACSFieldValue value={value} />) : ''}
        </Typography>)
    }
}

ACSField.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
};

const ACSRouteIcon = props => {
    switch(props.value._value) {
        case 'Static':
            return <Tooltip title={"Static"}><Chip label="S" size="small"/></Tooltip>
        case 'X_MIKROTIK_Connect':
            return <Tooltip title={"Connected"}><Chip label="C" size="small"/></Tooltip>
        case 'X_MIKROTIK_Dynamic_Static':
            return <Tooltip title={"DHCP"}><Chip label="D" size="small"/></Tooltip>
        case 'X_MIKROTIK_BGP':
            return <Tooltip title={"BGP"}><Chip label="B" size="small"/></Tooltip>
        case 'OSPF':
            return <Tooltip title={"OSPF"}><Chip label="O" size="small"/></Tooltip>
        case 'RIP':
            return <Tooltip title={"RIP"}><Chip label="R" size="small"/></Tooltip>
        default:
            return <Tooltip title={"Unknown"}><Chip label="U" size="small"/></Tooltip>
    }
}

const ACSEnableIcon = props => {
    switch(props.value._value) {
        case true:
            return <CheckCircleIcon color={"success"}/>
        case false:
            return <CancelIcon color={"error"}/>
        default:
            return <Chip label="U" size="small"/>
    }
}

const ACSStatusIcon = props => {
    switch(props.value._value) {
        case 'Up':
            return <CheckCircleIcon color={"success"}/>
        case 'Enabled':
            return <CheckCircleIcon color={"success"}/>
        case 'Disabled':
            return <CancelIcon color={"error"}/>
        case 'Down':
            return <CancelIcon color={"error"}/>
        default:
            return <Chip label="U" size="small"/>
    }
}

const ProvisionButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    if (!record) return <React.Fragment/>;

    return  <Button label={"Provision"} onClick={e => {
                e.stopPropagation();
                dataProvider.provisionCpeAcs(record.id).then(response => {
                    const {json} = response;
                    console.log(json);
                    notify('Provision launched', {type: "success"});
                }).catch((e) => {
                    notify('Error: provision not launched', {type: "warning"})
                });
            }}>
                <SyncIcon/>
            </Button>
};

const UpgradeButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);
    const [disabledButton, setDisabledButton] = useState(true);
    const dataProvider = useDataProvider();

    const handleDialogClose = () => setOpen(false);

    const handleChange = (event, value) => {
        if(value !== ''){
            setDisabledButton(false);
        }
        setValue(value);
    };

    const handleClick = () => {
        dataProvider.getAvailableUpgradeCpeAcs().then(response => {
            setOptions(response.data);
        }).catch(() => {});
        setOpen(true);
    };

    const handleConfirm = () => {
      dataProvider.launchUpgradeCpeAcs(record.id, value).then(() => {
        notify('Upgrade launched');
      }).catch((e) => {
        notify('Error: Upgrade not launched', 'warning')
      });
      setOpen(false);
    };

    if (!record) return <Fragment/>;

    return <Fragment>
            <Button label="Upgrade" onClick={handleClick}>
                <GetAppIcon/>
            </Button>
            <Dialog
              disableEscapeKeyDown
              maxWidth="xs"
              aria-labelledby="confirmation-dialog-title"
              open={open}
            >
              <DialogTitle id="confirmation-dialog-title">Upgrade Firmware</DialogTitle>
              <DialogContent>
                  <Typography variant="body2">
                    Please select Firmware Version
                  </Typography>
                <RadioGroup
                  aria-label="Ringtone"
                  name="ringtone"
                  value={value}
                  onChange={handleChange}
                >
                  { options && options.length > 0 && options.map(option => (
                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                  ))}
                </RadioGroup>
              </DialogContent>
              <DialogActions>
                <Button label="Cancel" onClick={handleDialogClose} />
                  { options && options.length > 0 && <Button disabled={disabledButton} label="OK" onClick={handleConfirm}/> }
              </DialogActions>
            </Dialog>
          </Fragment>;
}

const RebootButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    if (!record) return <React.Fragment/>;

    return  <Button label={"Reboot"} onClick={e => {
                e.stopPropagation();
                dataProvider.rebootCpeAcs( record.id).then(() => {
                    notify('Reboot launched', {type: "success"});
                }).catch((e) => {
                    notify('Error: reboot not launched', {type: "warning"})
                });
            }}>
                <SettingsPowerIcon/>
            </Button>
};

const BandwidthButton = () => {
    const notify = useNotify();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    if (!record) return <React.Fragment/>;

    return  <Button label={"Bandwidth Test"} onClick={e => {
                e.stopPropagation();
                dataProvider.launchBandwidthTestCpeAcs(record.id).then(() => {
                    notify('Bandwidth test launched', {type: "success"});
                }).catch((e) => {
                    notify('Error: bandwidth test not launched', {type: "warning"})
                });
            }}>
                <CachedIcon/>
            </Button>
};

const ConfigVersionField = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [version, setVersion] = useState('');
    const [config, setConfig] = useState('');

    useEffect(()=>{
        setVersion(record.latest_version);
    }, [record]);

    useEffect(()=>{
        if(version !== '') {
            dataProvider.getCpeConfigVersion(record.id, {
                version: version
            }).then(response => {
                let content = hljs.highlightAuto(response.data.content).value;
                setConfig(content)
            });
        }
    }, [version]);

    if (!record) return <React.Fragment/>;

    const choices = [];
    if(record)
        record.list_version.map(x => {
            choices.push({id: x, name: x});
        })

    const handleChange = (event) => {
        if(event.target.value!== ''){
            setVersion(event.target.value);
        }
    };

    return <Form>
        <SelectInput source={"latest_version"} choices={choices} onChange={handleChange}/>
        { config !== '' && <Paper sx={{padding: "10px"}}><pre>
            <code class={"dust"} dangerouslySetInnerHTML={{__html: config}}></code>
        </pre></Paper>}
    </Form>
};


const CpeShowActions = ({ basePath, data, resource }) => {
    return <TopToolbar>
            <UpgradeButton basepath={basePath} resource={resource} record={data}/>
            <RebootButton basepath={basePath} resource={resource} record={data}/>
            <ProvisionButton basepath={basePath} resource={resource} record={data}/>
            <EditButton basepath={basePath} record={data}/>
        </TopToolbar>
};

const CpeShowBase = (props) => {
    const [acs, setAcs] = useState();
    const record = useRecordContext();
    const dataProvider = useDataProvider();

    useEffect(()=> {
        if (!record) return null;
        dataProvider.getCpeAcs(record.id).then(response => {
            setAcs(response.data)
        });
    }, [record])

    return (
        <ShowBase {...props}>
            <TabbedShowLayout>
                <Tab label="cpe.form.summary">
                    <TextField source="id" />
                    <TextField source={"name"} />
                    <TextField source={"model"} />
                    <TextField source={"sn"} />
                    <TextField source={"acs_id"} />
                    <TextField source={"states"} />
                    <ReferenceField label="Leoid" source="circuit" reference="circuits">
                        <TextField source="leoid" />
                    </ReferenceField>
                    <BooleanField source="enabled" />
                </Tab>
                <Tab label="cpe.form.acs">
                    { acs && <React.Fragment key={"acs-fragment"}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Paper sx={{padding: "10px"}}>
                                <Labeled label={"Information"}>
                                  <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell component="td" scope="row" sx={{padding: "0px"}}>
                                            <ACSField label={"First Seen"} value={acs['_registered']} />
                                        </TableCell>
                                    </TableRow>
                                   <TableRow>
                                        <TableCell component="td" scope="row" sx={{padding: "0px"}}>
                                            <ACSField label={"Last Inform"} value={acs['_lastInform']} />
                                        </TableCell>
                                    </TableRow>
                                   <TableRow>
                                        <TableCell component="td" scope="row" sx={{padding: "0px"}}>
                                            <ACSField label={"UpTime"} value={acs['Device'] && secondsToString(getAcsValue(acs, 'Device.DeviceInfo.UpTime'))} />
                                        </TableCell>
                                    </TableRow>
                                   <TableRow>
                                        <TableCell component="td" scope="row" sx={{padding: "0px"}}>
                                            <ACSField label={"Software Version"} value={acs['Device'] && getAcsValue(acs, 'Device.DeviceInfo.SoftwareVersion')} />
                                        </TableCell>
                                    </TableRow>
                                  <TableRow>
                                        <TableCell component="td" scope="row" sx={{padding: "0px"}}>
                                            <ACSField label={"Architecture Name"} value={acs['Device'] && getAcsValue(acs, 'Device.DeviceInfo.X_MIKROTIK_ArchName')} />
                                        </TableCell>
                                    </TableRow>
                                  </Table>
                                </Labeled>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                             <Paper sx={{padding: "10px"}}>
                                <Labeled label={"Bandwidth Test"}>
                                    <Table aria-label="simple table">
                                    <TableRow>
                                        <TableCell component="th" scope="row" sx={{padding: "5px", pl: "10px"}}>
                                            <ACSField label={"Download"} value={acs['Device'] && toBandwidth(getAcsValue(acs, 'Device.IP.Diagnostics.DownloadDiagnostics.PeriodOfFullLoading'))} />
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={{padding: "5px"}}>
                                            <ACSField label={"Upload"} value={acs['Device'] && toBandwidth(getAcsValue(acs, 'Device.IP.Diagnostics.UploadDiagnostics.PeriodOfFullLoading'))} />
                                        </TableCell>
                                    </TableRow>
                                   <TableRow>
                                        <TableCell component="th" scope="row" sx={{padding: "5px", pl: "10px"}}>
                                            <ACSField label={"Last Download Test"} value={acs['Device'] && getAcsValue(acs, 'Device.IP.Diagnostics.DownloadDiagnostics.BOMTime')} />
                                        </TableCell>
                                        <TableCell component="th" scope="row" sx={{padding: "5px"}}>
                                            <ACSField label={"Last Upload Test"} value={acs['Device'] && getAcsValue(acs, 'Device.IP.Diagnostics.UploadDiagnostics.BOMTime')} />
                                        </TableCell>
                                    </TableRow>
                                  <TableRow>
                                        <TableCell component="th" scope="row" sx={{padding: "5px", pl: "10px"}}>
                                            <ACSField label={"Last Download Status"} value={acs['Device'] && getAcsValue(acs, 'Device.IP.Diagnostics.DownloadDiagnostics.DiagnosticsState')} />
                                        </TableCell>
                                      <TableCell component="th" scope="row" sx={{padding: "5px"}}>
                                            <ACSField label={"Last Upload Status"} value={acs['Device'] && getAcsValue(acs, 'Device.IP.Diagnostics.UploadDiagnostics.DiagnosticsState')} />
                                        </TableCell>
                                    </TableRow>
                                        <TableRow>
                                            <TableCell align="center" colSpan={2} component="th" scope="row" sx={{padding: "5px", pl: "10px"}}>
                                                <BandwidthButton/>
                                            </TableCell>
                                        </TableRow>
                                  </Table>
                                </Labeled>
                            </Paper>
                        </Grid>
                    </Grid>
                    { acs['Device'] &&
                        <React.Fragment key={"acs-interface-fragment"}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Paper sx={{padding: "10px"}}>
                                        <Labeled label={"Interfaces"}>
                                          <Table aria-label="simple table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Enable</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>MAC Address</TableCell>
                                                <TableCell>BytesReceived</TableCell>
                                                <TableCell>BytesSent</TableCell>
                                                <TableCell>DiscardPacketsReceived</TableCell>
                                                <TableCell>DiscardPacketsSent</TableCell>
                                                <TableCell>ErrorsReceived</TableCell>
                                                <TableCell>ErrorsSent</TableCell>
                                                <TableCell>PacketsReceived</TableCell>
                                                <TableCell>PacketsSent</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {existAcsKey(acs, 'Device.Ethernet.Interface') && Object.keys(acs['Device']['Ethernet']['Interface']).map((key, index) => (
                                                    (typeof acs['Device']['Ethernet']['Interface'][key] == 'object' ?
                                                        <TableRow key={key}>
                                                          <TableCell component="th" scope="row">
                                                            {key}
                                                          </TableCell>
                                                          <TableCell align="right"><ACSEnableIcon value={acs['Device']['Ethernet']['Interface'][key]['Enable']}/></TableCell>
                                                          <TableCell align="right"><ACSStatusIcon value={acs['Device']['Ethernet']['Interface'][key]['Status']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['MACAddress']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['BytesReceived']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['BytesSent']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['DiscardPacketsReceived']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['DiscardPacketsSent']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['ErrorsReceived']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['ErrorsSent']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['PacketsReceived']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['Ethernet']['Interface'][key]['Stats']['PacketsSent']}/></TableCell>
                                                        </TableRow>
                                                    : <React.Fragment/>)
                                                ))}
                                            </TableBody>
                                          </Table>
                                        </Labeled>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper sx={{padding: "10px"}}>
                                        <Labeled label={"IPs"}>
                                          <Table aria-label="simple table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>Interface</TableCell>
                                                <TableCell>ID</TableCell>
                                                <TableCell>IPAddress</TableCell>
                                                <TableCell>SubnetMask</TableCell>
                                                <TableCell>AddressingType</TableCell>
                                                <TableCell>Enable</TableCell>
                                                <TableCell>Status</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {existAcsKey(acs, 'Device.IP.Interface') && Object.keys(acs['Device']['IP']['Interface']).map((key, index) => (
                                                    (typeof acs['Device']['IP']['Interface'][key] == 'object' ?
                                                          Object.keys(acs['Device']['IP']['Interface'][key]['IPv4Address']).map((skey, sindex) => (
                                                              (typeof acs['Device']['IP']['Interface'][key]['IPv4Address'][skey] == 'object' ?
                                                        <TableRow key={key+"-"+skey}>
                                                            {sindex === 0 ? <TableCell component="th" scope="row" rowSpan={Object.keys(acs['Device']['IP']['Interface'][key]['IPv4Address']).length-3}>
                                                            {key}
                                                          </TableCell> : <React.Fragment/>}
                                                          <TableCell align="right"><ACSField value={skey}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['IP']['Interface'][key]['IPv4Address'][skey]['IPAddress']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['IP']['Interface'][key]['IPv4Address'][skey]['SubnetMask']}/></TableCell>
                                                          <TableCell align="right"><ACSField value={acs['Device']['IP']['Interface'][key]['IPv4Address'][skey]['AddressingType']}/></TableCell>
                                                          <TableCell align="right"><ACSEnableIcon value={acs['Device']['IP']['Interface'][key]['IPv4Address'][skey]['Enable']}/></TableCell>
                                                          <TableCell align="right"><ACSStatusIcon value={acs['Device']['IP']['Interface'][key]['IPv4Address'][skey]['Status']}/></TableCell>
                                                        </TableRow> : <React.Fragment/>)
                                                          ))
                                                    : <React.Fragment/>)
                                                ))}
                                            </TableBody>
                                          </Table>
                                        </Labeled>
                                    </Paper>
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper sx={{padding: "10px"}}>
                                        <Labeled label={"Routes"}>
                                          <Table aria-label="simple table">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Type</TableCell>
                                                <TableCell>DestIPAddress</TableCell>
                                                <TableCell>DestSubnetMask</TableCell>
                                                <TableCell>GatewayIPAddress</TableCell>
                                                <TableCell>Enable</TableCell>
                                                <TableCell>Status</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {existAcsKey(acs, 'Device.Routing.Router') && Object.keys(acs['Device']['Routing']['Router']).map((key, index) => (
                                                    (typeof acs['Device']['Routing']['Router'][key] == 'object' ?
                                                          Object.keys(acs['Device']['Routing']['Router'][key]['IPv4Forwarding']).map((skey, sindex) => {
                                                              if(typeof acs['Device']['Routing']['Router'][key]['IPv4Forwarding'][skey] == 'object') {
                                                                  let route_origin = acs['Device']['Routing']['Router'][key]['IPv4Forwarding'][skey]['Origin']
                                                                  let dest_address = acs['Device']['Routing']['Router'][key]['IPv4Forwarding'][skey]['DestIPAddress'];
                                                                  let dest_subnet = acs['Device']['Routing']['Router'][key]['IPv4Forwarding'][skey]['DestSubnetMask'];
                                                                  let route_gateway = acs['Device']['Routing']['Router'][key]['IPv4Forwarding'][skey]['GatewayIPAddress'];
                                                                  let route_enable = acs['Device']['Routing']['Router'][key]['IPv4Forwarding'][skey]['Enable'];
                                                                  let route_status = acs['Device']['Routing']['Router'][key]['IPv4Forwarding'][skey]['Status'];

                                                                  if(dest_address._value === '' && dest_subnet._value === '') {
                                                                      dest_address = '0.0.0.0';
                                                                      dest_subnet = '0.0.0.0'
                                                                  }

                                                                  return <TableRow key={key+"-"+skey}>

                                                          <TableCell align="right"><ACSField value={skey}/></TableCell>
                                                          <TableCell align="right"><ACSRouteIcon value={route_origin}/></TableCell>
                                                          <TableCell align="right"><ACSField value={dest_address}/></TableCell>
                                                          <TableCell align="right"><ACSField value={dest_subnet}/></TableCell>
                                                          <TableCell align="right"><ACSField value={route_gateway}/></TableCell>
                                                          <TableCell align="right"><ACSEnableIcon value={route_enable}/></TableCell>
                                                          <TableCell align="right"><ACSStatusIcon value={route_status}/></TableCell>
                                                        </TableRow>
                                                              }
                                                              return <React.Fragment/>;
                                                        })
                                                    : <React.Fragment/>)
                                                ))}
                                            </TableBody>
                                          </Table>
                                        </Labeled>
                                    </Paper>
                                </Grid>
                            </Grid>

                        </React.Fragment>
                    }
                    </React.Fragment> }
                </Tab>
                <Tab label="cpe.form.configs">
                    <ConfigVersionField />
                </Tab>
                <Tab label="cpe.form.miscellaneous">
                    <TextField source="applied_configuration" />
                    <TextField source="url_netbox" label="Url Netbox" />
                </Tab>
                <Tab label="cpe.form.objects">
                    <DateField source="created" showTime/>
                    <ReferenceField label="Created By" source="created_by" reference="users">
                        <TextField source="email" />
                    </ReferenceField>
                    <DateField source="modified" showTime/>
                    <ReferenceField label="Modified By" source="modified_by" reference="users">
                        <TextField source="email" />
                    </ReferenceField>
                </Tab>
            </TabbedShowLayout>
        </ShowBase>
    )
};

const CpeShow = (props) => (
    <Show title={<CpeTitle />} actions={<CpeShowActions/>} {...props} emptyWhileLoading>
        <CpeShowBase {...props}/>
    </Show>
)


export default CpeShow;
