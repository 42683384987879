import SearchIcon from '@mui/icons-material/Search';
import SyncIcon from '@mui/icons-material/Sync';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import {
    useNotify,
    fetchUtils,
    BulkDeleteButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ShowButton,
    SimpleList,
    TextField,
    ReferenceField,
    Button,
    TextInput,
    usePermissions
} from 'react-admin';
import * as Constants from "../../constants";
import UpgradeButton from "./UpgradeButton";
import RebootButton from "./RebootButton";
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";

const CpeFilter = props => (
    <Filter {...props}>
        <TextInput
            label="cpe.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const CpeListBulkActions = props => (
    <Fragment>
        <UpgradeButton {...props} />
        <RebootButton label={"Reboot CPE"} {...props} />
        <BulkDeleteButton  {...props}/>
    </Fragment>
);

const ProvisionButton = (props) => {
    const notify = useNotify();
    return  <Button label={"Provision"} onClick={e => {
                e.stopPropagation();
                let apiUrl = Constants.APIURL;
                let options = {method: 'POST'};
                let resource = props.resource;
                let id = props.record.id;
                let url = `${apiUrl}/${resource}/${id}/provision/`;
                fetchUtils.fetchJson(url, options).then(response => {
                    const {json} = response;
                    console.log(json);
                    notify('Provision launched');
                }).catch((e) => {
                    notify('Error: provision not launched', 'warning')
                });
            }}>
                <SyncIcon/>
            </Button>
};

const CpeList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const {permissions} = usePermissions();
    const styles = useStyles();
    return (
        <List
        {...props}
        bulkActionButtons={<CpeListBulkActions />}
        filters={<CpeFilter />}
        sort={{ field: 'id', order: 'ASC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.company_name}
                    secondaryText={record => `${record.leoid}`}
                    tertiaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ):(
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" cellClassName={styles.name} />
                    <ReferenceField label="Leoid" source="circuit" reference="circuits" sort={{field: 'leoid', order: 'ASC'}}>
                        <TextField source="leoid"/>
                    </ReferenceField>
                    <TextField source="sn" />
                    <TextField source="model"/>
                    <TextField source="states"/>
                    <ListActionsToolbar>
                        {permissions !== 'Viewer' ? <EditButton /> : <Fragment/> }
                        <ShowButton />
                        <ProvisionButton/>
                    </ListActionsToolbar>
                </Datagrid>
            )}
    </List>
    )
};

export default CpeList;
