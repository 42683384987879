import React from 'react';
import {
    Edit,
    FormTab,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    TabbedForm,
    TextInput,
    NumberInput, usePermissions
} from 'react-admin';
import CircuitTitle from './CircuitTitle';

const CircuitEdit = props => {
    const {permissions} = usePermissions();

    let choices = [
        {id: 'planned', name: 'Planned'},
    ];
    if(['admin','deploy'].includes(permissions))
        choices = [
            {id: 'new', name: 'New'},
            {id: 'active', name: 'Active'},
            {id: 'deprovisioning', name: 'Deprovisioning'},
            {id: 'planned', name: 'Planned'},
            {id: 'provisioning', name: 'Provisioning'},
            {id: 'offline', name: 'Offline'},
            {id: 'decommissioned', name: 'Decommissioned'},
            {id: 'provisionningerror', name: 'Provisionning Error'},
            {id: 'conferror', name: 'Configuration Error'},
        ]

    return <Edit title={<CircuitTitle/>} {...props}>
        <TabbedForm>
            <FormTab label="circuit.form.summary">
                <TextInput disabled source="id"/>
                <TextInput disabled source="leoid"/>
                <ReferenceInput label="Client" source="client" reference="companies"
                                sort={{field: 'nom', order: 'ASC'}}>
                    <AutocompleteInput optionText="nom"/>
                </ReferenceInput>
                <SelectInput source="status" choices={choices}/>
            </FormTab>
            <FormTab label="circuit.form.circuit">
                <ReferenceInput label="Type" source="type" reference="typescircuit"
                                sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput label="Collect L2TP" source="collect_l2tp" reference="collectsl2tp"
                                sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput label="Collect L2" source="collect_l2" reference="collectsl2"
                                sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="vlan_client" label="Vlan client"/>
                <NumberInput source={"debit"} label="Débit"/>
            </FormTab>
        </TabbedForm>
    </Edit>
};

export default CircuitEdit;
