import React from 'react';
import {
    BooleanField,
    NumberField,
    ReferenceField,
    DateField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField
} from 'react-admin';
import L2tpTitle from './L2tpTitle';

const L2tpShow = () => (
    <Show  title={<L2tpTitle />}>
        <TabbedShowLayout>
            <Tab label="l2tp.form.summary">
                <TextField source="id" />
                <ReferenceField label="Client" source="company" reference="companies" linkType={false}>
                    <TextField source="nom" />
                </ReferenceField>
                <TextField source="circuit_type" />
                <ReferenceField label="Type" source="circuit" reference="circuits">
                    <TextField source="type" />
                </ReferenceField>
                <ReferenceField label="Collecte" source="collect" reference="collectsl2tp">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="enabled" />
            </Tab>
            <Tab label="l2tp.form.network">
                <TextField source="username" />
                <ReferenceField label="Realm" source="realm" reference="realms">
                    <TextField source="domain" />
                </ReferenceField>
                <TextField source="password" />
                <TextField source="ipv4_address" />
                <TextField source="ipv6_address" />
                <TextField source="ipv6_prefix" />
                <NumberField source="bw_down" />
                <NumberField source="bw_up" />
            </Tab>
            <Tab label="l2tp.form.miscellaneous">
                <TextField source="comment" />
            </Tab>
            <Tab label="l2tp.form.objects">
                <DateField source="created" showTime/>
                <ReferenceField label="Created By" source="created_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
                <DateField source="modified" showTime/>
                <ReferenceField label="Modified By" source="modified_by" reference="users">
                    <TextField source="email" />
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default L2tpShow;
