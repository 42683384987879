import React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    BooleanInput,
    required,
} from 'react-admin';
import IPRealmTitle from './IPRealmTitle';

const IPRealmEdit = props => (
    <Edit title={<IPRealmTitle />} {...props}>
         <TabbedForm>
            <FormTab label="iprealm.form.summary">
                <TextInput disabled source="id" />
                <TextInput source="ip" validate={required()} />
                <BooleanInput source="enabled"/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default IPRealmEdit;
