import React, { Fragment } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    List,
    MenuItem,
    ListItemIcon,
    Typography,
    Divider,
    Collapse,
    Tooltip
} from '@mui/material';
import PropTypes from "prop-types";

const SubMenu = ({
    handleToggle,
    sidebarIsOpen,
    isOpen,
    name,
    icon,
    children,
    dense,
}) => {

    const header = (
        <MenuItem dense={dense} onClick={handleToggle}>
            <ListItemIcon sx={{ minWidth: 5, paddingRight: sidebarIsOpen ? 0 : 2 }}>
                {isOpen ? <ExpandMore /> : icon}
            </ListItemIcon>
            <Typography variant="inherit" color="textSecondary">
                {name}
            </Typography>
        </MenuItem>
    );

    return (
        <Fragment>
            {sidebarIsOpen || isOpen ? (
                header
            ) : (
                <Tooltip title={name} placement="right">
                    {header}
                </Tooltip>
            )}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List
                    dense={dense}
                    component="div"
                    disablePadding
                    sx={{
                        '& a': {
                            transition:
                                'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                            paddingLeft: sidebarIsOpen ? 4 : 2,
                        },
                    }}
                >
                    {children}
                </List>
                <Divider />
            </Collapse>
        </Fragment>
    );
};

SubMenu.propTypes = {
    dense: PropTypes.bool,
    handleToggle: PropTypes.func,
    icon: PropTypes.element,
    isOpen: PropTypes.bool,
    name: PropTypes.string,
    sidebarIsOpen: PropTypes.bool,
};

export default SubMenu;