import BookIcon from '@mui/icons-material/Book';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import {
    BulkDeleteButton,
    Datagrid,
    EditButton,
    Filter,
    List,
    ReferenceArrayField,
    SingleFieldList,
    ShowButton,
    SimpleList,
    BooleanField,
    TextField,
    TextInput,
    usePermissions
} from 'react-admin';
import RealmsTitle from './RealmsTitle';
import ListActionsToolbar from "../../components/Widget/ListActionsToolbar";

export const PostIcon = BookIcon;

const RealmsFilter = props => (
    <Filter {...props}>
        <TextInput
            label="collectl2tp.list.search"
            source="q"
            alwaysOn
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon color="disabled" />
                    </InputAdornment>
                ),
            }}
        />
    </Filter>
);

const useStyles = makeStyles(theme => ({
    company: {
        maxWidth: '20em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    textip: {
        marginLeft: '2px',
        marginRight: '2px',
        backgroundColor: '#e0e0e0',
        borderRadius: '16px',
        paddingLeft: '4px',
        paddingRight: '4px'

    },
    hiddenOnSmallScreens: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    created: { fontStyle: 'italic' },
}));

const RealmsListBulkActions = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const RealmsList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const styles = useStyles();
    const {permissions} = usePermissions();
    return (
        <List
        {...props}
        title={<RealmsTitle />}
        bulkActionButtons={<RealmsListBulkActions />}
        filters={<RealmsFilter />}
        sort={{ field: 'id', order: 'DESC' }}
    >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record =>
                        new Date(record.created).toLocaleDateString()}
                />
            ):(
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="domain" cellClassName={styles.name} />
                    <TextField source="tunnelid" cellClassName={styles.name} />
                    <ReferenceArrayField label="IPs" source="ips" reference="ip_realm" linkType={false}>
                        <SingleFieldList linkType="show">
                            <TextField className={styles.textip} source="ip" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <BooleanField source="remote" />
                    <ReferenceArrayField label="Radius" source="radius_servers" reference="radiusrealm" linkType={false}>
                        <SingleFieldList linkType="show">
                            <TextField className={styles.textip} source="addr" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <TextField source="comment" cellClassName={styles.comment} />
                    <BooleanField source="enabled" />
                    <ListActionsToolbar>
                        {permissions === 'admin' ? <EditButton /> : <Fragment/> }
                        <ShowButton />
                    </ListActionsToolbar>
                </Datagrid>
            )}
    </List>
    )
};

export default RealmsList;
