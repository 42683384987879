import React, {Fragment} from 'react';
import {
    Edit,
    FormTab,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    TabbedForm,
    TextInput,
    FormDataConsumer,
    required,
} from 'react-admin';
import RealmsTitle from './RealmsTitle';
import IPRealmQuickCreateButton from "./IPRealmQuickCreateButton";

const IPRealmReferenceInput = props => (
  <Fragment>
    <ReferenceArrayInput {...props}>
      <AutocompleteArrayInput source="ips" optionText="ip" optionValue="id"/>
    </ReferenceArrayInput>
    <IPRealmQuickCreateButton />
  </Fragment>
);

const RealmsEdit = props => (
    <Edit title={<RealmsTitle />} {...props}>
         <TabbedForm>
            <FormTab label="collectl2tp.form.summary">
                <TextInput disabled source="id" />
                <TextInput source="domain" validate={required()} />
                <TextInput source="tunnelid" />
                <IPRealmReferenceInput label="LNS IPs" source="ips" reference="ip_realm" sort={{field: 'ip', order: 'ASC'}} validate={required()} />
                <BooleanInput source="enabled" defaultValue />
                <BooleanInput source="remote" defaultValue />
                <FormDataConsumer>
                 {({ formData, ...rest }) => formData.remote &&
                   <ReferenceArrayInput label="Radius" source="radius_servers" reference="radiusrealm" sort={{field: 'addr', order: 'ASC'}}>
                    <AutocompleteArrayInput source="radiusrealm" optionText="addr" optionValue="id"/>
                   </ReferenceArrayInput>
                 }
                </FormDataConsumer>
            </FormTab>
            <FormTab label="collectl2tp.form.miscellaneous">
                <TextInput multiline source="comment" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default RealmsEdit;
