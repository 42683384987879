import React from 'react';
import {
    Create,
    AutocompleteInput,
    ReferenceInput,
    SaveButton,
    SimpleForm,
    Toolbar,
    NumberInput, useNotify, useRedirect, FormDataConsumer
} from 'react-admin';
import {useFormContext} from "react-hook-form";

const CircuitCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/circuits', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

const CircuitCreate = props => (
    <Create {...props}>
        <SimpleForm
            toolbar={<CircuitCreateToolbar />}
            validate={values => {
                const errors = {};
                ['client', 'type'].forEach(field => {
                    if (!values[field]) {
                        errors[field] = ['Required field'];
                    }
                });

                return errors;
            }}
        >
            <ReferenceInput label="Company" source="client" reference="companies" sort={{field: 'nom', order: 'ASC'}}>
                <AutocompleteInput optionText="nom"/>
            </ReferenceInput>
            <ReferenceInput label="Type" source="type" reference="typescircuit" sort={{field: 'name', order: 'ASC'}}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>


             <FormDataConsumer>
                {({ formData, ...rest }) => [2, 4, 5].indexOf(formData.type) !== -1 &&
                <ReferenceInput label="Collect L2TP" source="collect_l2tp" reference="collectsl2tp" sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                }
             </FormDataConsumer>

             <FormDataConsumer>
                {({ formData, ...rest }) => [3, 9, 10, 11, 12].indexOf(formData.type) !== -1 &&
                <ReferenceInput label="Collect L2" source="collect_l2" reference="collectsl2" sort={{field: 'name', order: 'ASC'}}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                }
             </FormDataConsumer>
            <FormDataConsumer>
                {({ formData, ...rest }) => [3, 9, 10, 11, 12].indexOf(formData.type) !== -1 &&
                <NumberInput source="vlan_client" label="Vlan client"/>
                }
             </FormDataConsumer>
            <NumberInput source={"debit"}/>
        </SimpleForm>
    </Create>
);

export default CircuitCreate;
