import React, { Component } from "react";
import {
    BooleanInput,
    Create,
    SaveButton,
    SimpleForm,
    TextInput,
    Toolbar,
    required, useNotify, useRedirect
} from 'react-admin';
import {useFormContext} from "react-hook-form";

const RouterCreateToolbar = props => {
    const notify = useNotify();
    const redirect = useRedirect();
    const { reset } = useFormContext();

    return (
        <Toolbar {...props}>
            <SaveButton
                label="form.action.save_and_show"
                type="button"
                mutationOptions={{
                    onSuccess: data => {
                        notify('ra.notification.updated', {
                            type: 'info',
                            messageArgs: {smart_count: 1},
                            undoable: true,
                        });
                        redirect('show', '/routers', data.id)
                    }
                }}
            />
            <SaveButton
                label="form.action.save_and_add"
                type="button"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Element created');
                        reset();
                    }}
                }
                variant="text"
            />
        </Toolbar>
    )
};

class RouterCreate extends Component {
  render() {
    return (
      <Create
        {...this.props} >
          <SimpleForm
              defaultValues={this.state}
              toolbar={<RouterCreateToolbar />}
          >
              <TextInput source="name" validate={required()}/>
              <TextInput source="ipv4_address" label="IP Loopback 0" validate={required()}/>
              <BooleanInput source="enable" defaultValue />
              <TextInput source="netbox_id" validate={required()} />
              <TextInput source="comment" />
          </SimpleForm>
      </Create>
   )
  }
}

export default RouterCreate;
