import englishMessages from 'ra-language-english';

export const messages = {
    simple: {
        action: {
            resetViews: 'Reset views',
        },
    },
    form: {
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        }
    },
    ...englishMessages,
    resources: {
        l2tp: {
            name: 'L2TP |||| L2TP',
            fields: {
                company: 'Company',
                comment: 'Comment',
                enable: 'Enable',
                created: 'Created at',
                modified: 'Modified at',
                created_by: 'Created by',
                modified_by: 'Modified by',
                ipv4_address: 'IPv4 Address',
                ipv6_address: 'IPv6 Address',
                ipv6_prefix: 'IPv6 Prefix',
                bw_down: 'Bandwidth Download',
                bw_up: 'Bandwidth Upload',
            },
        },
        users: {
            name: 'User |||| Users',
            fields: {
                firstname: 'Firstname',
                lastname: 'Lastname',
                email: 'E-mail',
                role: 'Role',
            },
        },
    },
    l2tp: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
        },
        edit: {
            title: 'L2TP %{title}',
        },
        show: {
            title: 'L2TP',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    circuit: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
            circuit: 'Circuit',
        },
        edit: {
            title: 'Circuit %{title}',
        },
        show: {
            title: 'Circuit',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    collectl2: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
        },
        edit: {
            title: 'Collect L2 %{title}',
        },
        show: {
            title: 'Collect L2',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    collectl2tp: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
        },
        edit: {
            title: 'Collect L2TP %{title}',
        },
        show: {
            title: 'Collect L2TP',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    typecircuit: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
            circuit: 'Circuit',
        },
        edit: {
            title: 'Circuit %{title}',
        },
        show: {
            title: 'Circuit',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
     realm: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
            realm: 'Realm',
            remote: 'Remote'
        },
        edit: {
            title: 'Realm %{title}',
        },
        show: {
            title: 'Realm',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    iprealm: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
            realm: 'Realm',
            remote: 'Remote'
        },
        edit: {
            title: 'IP %{title}',
        },
        show: {
            title: 'IP',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    radiusrealm: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
            realm: 'Realm',
            remote: 'Remote',
            security: 'Security'
        },
        edit: {
            title: 'Radius %{title}',
        },
        show: {
            title: 'Radius',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    clientvrf: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            variables: 'Variables',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments'
        },
        edit: {
            title: 'VRF Client %{title}',
        },
        show: {
            title: 'VRF Client',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    cpe: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            configs: 'Configs',
            variables: 'Variables',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
            acs: 'ACS'
        },
        edit: {
            title: 'CPE %{title}',
        },
        show: {
            title: 'CPE',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    transitgw: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            variables: 'Variables',
            network: 'Network',
            miscellaneous: 'Miscellaneous',
            transit: 'Transit',
            objects: 'Informations',
            comments: 'Comments'
        },
        edit: {
            title: '%{title}',
        },
        show: {
            title: '%{title}',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    router: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            variables: 'Variables',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments'
        },
        edit: {
            title: 'Router %{title}',
        },
        show: {
            title: 'Router',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    configs: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            body: 'Body',
            variables: 'Variables',
            miscellaneous: 'Miscellaneous',
            objects: 'Informations',
            comments: 'Comments',
            generation: 'Generation',
            data: 'Data',
            voice: 'Voice',
            intersite: 'Intersite',
            wifi: 'WiFi',
            l2tp: 'L2TP',
            conf: 'Configuration'
        },
        edit: {
            title: 'Config %{title}',
        },
        show: {
            title: 'Config',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
    user: {
        list: {
            search: 'Search',
        },
        form: {
            summary: 'Summary',
            security: 'Security',
        },
        edit: {
            title: 'User %{title}',
        },
        show: {
            title: 'Users',
        },
        action: {
            save_and_add: 'Save and Add',
            save_and_show: 'Save and Show',
        },
    },
};

export default messages;
