import React, {Fragment} from 'react';
import {
    BooleanField,
    NumberField,
    ReferenceField,
    DateField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField, useShowContext, useGetOne
} from 'react-admin';
import TransitGWTitle from './TransitGWTitle';
import {BandwidthField} from "../../components/Fields/BandwidthField";

const TransitGWShowLayout = () => {
    const {
        defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        error,  // error returned by dataProvider when it failed to fetch the record. Useful if you want to adapt the view instead of just showing a notification using the `onError` side effect.
        isFetching, // boolean that is true while the record is being fetched, and false once the record is fetched
        isLoading, // boolean that is true until the record is available for the first time
        record, // record fetched via dataProvider.getOne() based on the id from the location
        refetch, // callback to refetch the record via dataProvider.getOne()
        resource, // the resource name, deduced from the location. e.g. 'posts'
    } = useShowContext();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Error!</div>;
    }

    return <TabbedShowLayout>
        <Tab label="transitgw.form.summary">
            <TextField source="id"/>
            <TextField source="leoid"/>
            <ReferenceField label="Client" source="company" reference="companies" linkType={false}>
                <TextField source="nom"/>
            </ReferenceField>
            <TextField source="circuit_type"/>
            <ReferenceField label="Type" source="circuit" reference="circuits">
                <TextField source="type"/>
            </ReferenceField>
            <BooleanField source="enabled"/>
        </Tab>
        <Tab label="transitgw.form.network">
            <TextField source="ipv4_address"/>
            <TextField source="ipv6_address"/>
            <BandwidthField source="commit"/>
            <BandwidthField source="port"/>
            {record && record.circuit_type === 'Transit' && <TextField source="asn" label={"ASN"}/>}
            {record && record.circuit_type === 'Transit' && <BooleanField source={"originate"} label={"Originate"}/>}
            {record && record.circuit_type === 'Transit' && <BooleanField source={"bfd"} label={"BFD"}/>}
            {record && record.circuit_type === 'Transit' && <TextField source="password" label={"Password"}/>}
            {record && record.circuit_type === 'Transit' && <TextField source="import_policy" label={"Import Policy"}/>}
            {record && record.circuit_type === 'Transit' && <TextField source="export_policy" label={"Export Policy"}/>}
            {record && record.circuit_type === 'Transit' && <TextField source="peering_manager_connid" label={"Peering Manager Connection ID"}/>}
        </Tab>
        <Tab label="transitgw.form.miscellaneous">
            <TextField source="comment"/>
        </Tab>
        <Tab label="transitgw.form.objects">
            <DateField source="created" showTime/>
            <ReferenceField label="Created By" source="created_by" reference="users">
                <TextField source="email"/>
            </ReferenceField>
            <DateField source="modified" showTime/>
            <ReferenceField label="Modified By" source="modified_by" reference="users">
                <TextField source="email"/>
            </ReferenceField>
        </Tab>
    </TabbedShowLayout>
};

const TransitGWShow = () => (
    <Show title={<TransitGWTitle/>}>
        <TransitGWShowLayout/>
    </Show>
)

export default TransitGWShow;
