import React, { useState } from 'react';
import {
    required,
    Button,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
    Form
} from 'react-admin';
import IconContentAdd from "@mui/icons-material/Add";
import IconCancel from "@mui/icons-material/Cancel";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";

const IPRealmQuickCreateButton = (onChange) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('posts');
  const notify = useNotify();

  const handleClick = () => {
      setShowDialog(true);
  };

  const handleCloseClick = () => {
      setShowDialog(false);
  };

  const handleSubmit = async values => {
      create(
          { payload: { data: values } },
          {
              onSuccess: ({ data }) => {
                  setShowDialog(false);
                  // Update the comment form to target the newly created post
                  // Updating the ReferenceInput value will force it to reload the available posts
                  //form.change('ip_realm', data);
                  //sendIP(values);
                  onChange();
              },
              onFailure: ({ error }) => {
                  notify(error.message, 'error');
              }
          }
      );
  };

  return (
      <>
        <Button onClick={handleClick} label="ra.action.create">
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label="Create IP for Realm"
        >
          <DialogTitle>Create IP for Realm</DialogTitle>
          <Form
                resource="posts"
                onSubmit={handleSubmit}
          >
             <DialogContent>
                <TextInput source="ip" validate={required()} />
            </DialogContent>
            <DialogActions>
                <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                >
                    <IconCancel />
                </Button>
                <SaveButton/>
            </DialogActions>
          </Form>
        </Dialog>
      </>
    );
}

export default IPRealmQuickCreateButton;