import React from 'react';
import {
    BooleanInput,
    Edit,
    FormTab,
    AutocompleteInput,
    ReferenceInput,
    NumberInput,
    TabbedForm,
    TextInput,
    number,
    required, SaveButton, DeleteButton, Toolbar,
} from 'react-admin';
import L2tpTitle from './L2tpTitle';

const CustomL2tpEditToolbar = () => (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SaveButton />
        <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
);
const L2tpEdit = props => (
    <Edit title={<L2tpTitle />} {...props}>
        <TabbedForm toolbar={<CustomL2tpEditToolbar />}>
            <FormTab label="l2tp.form.summary">
                <TextInput disabled source="id" />
                <ReferenceInput label="Company" source="company" reference="companies" sort={{field: 'nom', order: 'ASC'}}>
                    <AutocompleteInput optionText="nom"/>
                </ReferenceInput>
                <TextInput disabled source="leoid" />
                <BooleanInput source="enabled" defaultValue />
            </FormTab>
            <FormTab label="l2tp.form.network">
                <TextInput source="username" />
                <ReferenceInput label="Realm" source="realm" reference="realms" sort={{field: 'domain', order: 'ASC'}}>
                    <AutocompleteInput optionText="domain"/>
                </ReferenceInput>
                <TextInput source="password" />
                <TextInput source="ipv4_address" validate={required()} />
                <TextInput source="ipv6_address" validate={required()} />
                <TextInput source="ipv6_prefix" validate={required()} />
                <NumberInput source="bw_down" validate={[number()]} />
                <NumberInput source="bw_up" validate={[number()]}/>
            </FormTab>
            <FormTab label="l2tp.form.miscellaneous">
                <TextInput multiline source="comment" />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default L2tpEdit;
